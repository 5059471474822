/* 宿舍报修 */
export const repairRouter = [
  {
    path: '/repair/applyTable',
    name: 'applyTable',
    component: () => import('@/views/pages/repairManage/index.vue'),
    meta: {
      title: '申报表',
      role: ['超级管理员']
    }
  },
  {
    path: '/repair/waterFeeRecord',
    name: 'waterFeeRecord',
    component: () => import('@/views/pages/repairManage/water-fee-record/waterFeeRecord.vue'),
    meta: {
      title: '学生用水记录',
      role: ['超级管理员']
    }
  }
]
