import request from '@/service/request'

// 获取学院列表
const getCollegeList = (params) => {
  return request.post('/sch/college/page', params)
}

// 添加学院
const addCollege = (params) => {
  return request.post('/sch/college/save', params)
}

// 删除学院
const deleteCollege = (params) => {
  return request.post('/sch/college/del/' + params)
}

// 根据id获取学院信息
const getCollegeById = (params) => {
  return request.post('/sch/college/getById/' + params)
}

// 根据校区id获取院系
const getCollegeByCampusId = (params) => {
  return request.post('/sch/college/listByCampusId/' + params)
}

// 添加查询院系
/**
 * @param {String} campusId 校区id
 * @param {String} majorId 专业ID
 */
const getCollegeListQuery = (params) => {
  return request.post('/sch/college/list', params)
}

export {
  getCollegeList,
  addCollege,
  deleteCollege,
  getCollegeById,
  getCollegeByCampusId,
  getCollegeListQuery
}
