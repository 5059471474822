import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import component from '@/components/common'
import './plugins/element.js'
import { permission, role } from './directive/index'
import md5 from 'js-md5'
import './assets/scss/index.scss'
import './assets/iconfont/iconfont/iconfont.css'
import './assets/iconfont/fontAwesome/font-awesome.css'
import './router/beforeEach'
import './api/common'
import './utils/common-lodash'
import moment from 'moment'

// 定义全局时间戳过滤器
Vue.prototype.$moment = moment
moment.locale('zh-cn')
Vue.filter('formatDate', (str, defaultStr = 'YYYY-MM-DD') => moment(str).format(defaultStr))

Vue.prototype.$md5 = md5
Vue.config.productionTip = false
Vue.prototype.$fileUrl = process.env.VUE_APP_IMG_URL
Vue.prototype.$baseUrl = process.env.VUE_APP_API_URL
Vue.use(component)
Vue.use(permission)
Vue.use(role)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
