import request from '@/service/request'

/**
 * 菜单接口
 */
// 获取菜单列表
export const getMenuList = (params) => {
  return request.post('/sys/menu/listTree', params)
}

// 添加菜单
export const addMenu = (params) => {
  return request.post('/sys/menu/save', params)
}

// 根据id获取菜单信息
export const getMenuInfoById = (params) => {
  return request.post('/sys/menu/getById/' + params)
}

// 根据id删除菜单
export const deleteMenuById = (params) => {
  return request.post('/sys/menu/del/' + params)
}

// 修改密码
export const modUserPassword = (params) => {
  return request.post('/auth/editPass', params)
}
