<template>
  <el-dialog
    class='mod-password'
    title='修改密码'
    :visible.sync='show'
    width='400px'
    :before-close='handleClose'
    append-to-body
    :close-on-click-modal='false'
    @close='close'
  >
    <el-form ref='form' :rules='rules' :model='formData' label-width='80px'>
      <el-form-item label='旧密码' prop='oldPassword'>
        <!--   添加隐藏的input,用于抵消浏览器的自动填充密码功能     -->
        <ElInput class='hide-input' v-model='hidePwd' type='password' show-password />
        <el-input
          type='password'
          show-password
          v-model.trim='formData.oldPassword'
          placeholder='请输入旧密码' />
      </el-form-item>
      <el-form-item label='新密码' prop='newPassword'>
        <el-input
          type='password'
          show-password
          v-model.trim='formData.newPassword'
          placeholder='请输入新密码' />
      </el-form-item>
      <el-form-item label='确认密码' prop='confirmPassword'>
        <el-input
          type='password'
          show-password
          v-model.trim='formData.confirmPassword'
          placeholder='请再输入一次新密码' />
      </el-form-item>
    </el-form>
    <div slot='footer' class='dialog-footer'>
      <el-button @click='close'>取 消</el-button>
      <el-button type='primary' @click='confirmHandle'>确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'

export default {
  mixins: [dialog],
  props: {
    dialogData: {
      type: Object
    }
  },
  data () {
    return {
      hidePwd: '',
      formData: {
        newPassword: '',
        oldPassword: '',
        confirmPassword: ''
      },
      rules: {
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        oldPassword: [
          { required: true, message: '请输入当前密码', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请输入确认密码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 确定事件
    confirmHandle () {
      this.$refs.form.validate((valid) => {
        if (this.formData.newPassword !== this.formData.confirmPassword) {
          this.$message.error('两次新密码不一致!请重新输入!')
          return false
        }
        if (valid) {
          if (this.formData.newPassword === this.formData.confirmPassword) {
            this.$emit('confirm', {
              newPassword: this.$md5(this.formData.confirmPassword),
              oldPassword: this.$md5(this.formData.oldPassword)
            })
          }
        } else {
          this.$message('未通过校验,可能是漏填必填项!')
          return false
        }
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$confirm('确认关闭？')
        .then(() => {
          this.$emit('close')
        })
        .catch((_) => {
        })
    }
  }
}
</script>
<style lang='scss'>
.mod-password {
  .hide-input {
    display: none;
  }
}
</style>
