import baseDialog from './components/baseDialog.vue'
import vueTinymce from './components/vueTinymce.vue'
import basePagination from './components/basePagination.vue'
import baseUploadFile from './components/baseUploadFile.vue'
import baseUploadImage from './components/baseUploadImage.vue'
import headerSelect from './components/headerSelect.vue'
import baseSelectClass from './components/baseSelectClass.vue'
import baseBindTeacher from './components/baseBindTeacher.vue'
import baseNavTab from './components/baseNavTab.vue'
import SingleImgUploader from '@/components/common/components/singleImgUploader.vue'
import multipleImgUploader from '@/components/common/components/multipleImgUploader.vue'
import viewSearchForm from '@/components/common/components/viewSearchForm.vue'
import tableView from '@/components/common/components/tableView.vue'
import toolTipsGroup from '@/components/common/components/toolTipsGroup.vue'
import uploadExcel from '@/components/common/components/uploadExcel.vue'
import cancelPopover from '@/components/common/components/cancelPopover.vue'

// 全局注册组件
export default {
  install: function (Vue) {
    Vue.component('base-dialog', baseDialog)
    Vue.component('vue-tinymce', vueTinymce)
    Vue.component('base-pagination', basePagination)
    Vue.component('base-uploadImage', baseUploadImage)
    Vue.component('header-select', headerSelect)
    Vue.component('base-select-class', baseSelectClass)
    Vue.component('base-bind-teacher', baseBindTeacher)
    Vue.component('base-upload-file', baseUploadFile)
    Vue.component('base-navTab', baseNavTab)
    Vue.component('single-img-uploader', SingleImgUploader)
    Vue.component('multiple-img-uploader', multipleImgUploader)
    Vue.component('view-search-form', viewSearchForm)
    Vue.component('table-view', tableView)
    Vue.component('tool-tips-group', toolTipsGroup)
    Vue.component('upload-excel', uploadExcel)
    Vue.component('cancel-popover', cancelPopover)
  }
}
