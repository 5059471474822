<template>
  <div class='right-layout'>
    <div class='right-layout__header'>
      <c-header ref='header'></c-header>
      <base-navTab ref='navTab'></base-navTab>
    </div>
    <el-scrollbar class='right-layout__main'>
      <div class='main-body' :style='{ height: mainHeight }'>
        <c-main ref='main'></c-main>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import cHeader from './top/index.vue'
import cMain from './main/index.vue'

export default {
  components: {
    cHeader,
    cMain
  },
  data () {
    return {
      mainHeight: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      // const offsetHeight = this.$refs.header.$el.offsetHeight
      // const navTabHeight = this.$refs.navTab.$el.offsetHeight
      // this.mainHeight = `calc(100vh - ${offsetHeight + navTabHeight + 50}px)`
    })
  }
}
</script>

<style lang='scss' scoped>

.right-layout {
  --header-height: 96px;
  //用于隐藏.right-layout__main的滚动条
  height: calc(100vh + 17px);

  .right-layout__header {
    position: sticky;
    top: 0;
    left: 0;
    height: var(--header-height);
    z-index: 1000;
  }

  .right-layout__main {
    height: calc(100% - var(--header-height));
    background: #f3f5fb;

    .main-body {
      height: 100%;
      box-sizing: border-box;
    }
    ::v-deep .el-scrollbar__bar.is-vertical{
      z-index: 24;
    }
  }
}
</style>
