import request from '@/service/request'

// 获取专业列表
export const getMajorList = (params) => {
  return request.post('/sch/major/page', params)
}

// 添加专业
export const addMajor = (params) => {
  return request.post('/sch/major/save', params)
}

// 删除专业
export const deleteMajor = (params) => {
  return request.post('/sch/major/del/' + params)
}

// 根据id获取专业信息
export const getMajorById = (params) => {
  return request.post('/sch/major/getById/' + params)
}

// 根据院系id查询专业
export const getMajorByCollegeId = (params) => {
  return request.post('/sch/major/listByCollegeId/' + params)
}

// 添加查询所有专业
/**
 * @param {String} campusId 校区id
 * @param {String} collegeId 院系ID
 */
export const getMajorListQuery = (params) => {
  return request.post('/sch/major/list', params)
}

/* 根据专业id获取 不同年级所在校区 list
*  majorId{string} 专业id
* */
export const listCampusByMajorIdApi = (majorId) => {
  return request.post(`/sch/major/listSchMajorCampus/${majorId}`)
}

/* 根据专业id和年级获取所在校区
*  majorId {string} 专业id
*  grade {number} (学生)年级
*  */
export const getCampusMajorAndGradeApi = (params) => {
  return request.post('/sch/major/querySchMajorCampus', params)
}

/* 根据专业id 保存不同年级的所在校区
* */
export const saveCampusMajorApi = (params) => {
  return request.post('/sch/major/saveSchMajorCampus', params)
}
