import permission from './modules/permission'
import role from './modules/role'

const install = function (Vue) {
  Vue.directive('permission', permission)
  Vue.directive('role', role)
}

if (window.Vue) {
  window.permission = permission
  window.role = role
  Vue.use(install); // eslint-disable-line
}

permission.install = install
role.install = install

export {
  permission,
  role
}
