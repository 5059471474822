import request from '@/service/request'

// 获取班级列表
export const getClassesList = (params) => {
  return request.post('/sch/classes/page', params)
}

// 添加班级
export const addClasses = (params) => {
  return request.post('/sch/classes/save', params)
}

// 删除班级
export const deleteClasses = (params) => {
  return request.post('/sch/classes/del/' + params)
}

// 根据id获取班级信息
export const getClassesById = (params) => {
  return request.post('/sch/classes/getById/' + params)
}

// 根据校区id获取辅导员
export const getTeacherByCampusId = (params) => {
  return request.post('/sch/classes/getTea/' + params)
}

// 条件查询班级
/**
 * @param {String} grade 年级
 * @param {String} majorId 专业ID
 */
export const getClassListQuery = (params) => {
  return request.post('/sch/classes/list', params)
}
