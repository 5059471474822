<template>
  <el-dialog
    :title="dialogData.title"
    :visible.sync="dialogData.isOpen"
    width="30%"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="所属专业">
        <el-select v-model="formData.majorId" disabled @change="changeMajor">
          <el-option
            v-for="(item, index) in majorList"
            :key="index"
            :label="item.majorName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年级">
        <el-select v-model="formData.grade" @change="changeGrade">
          <el-option
            v-for="(item, index) in gradeList"
            :key="index"
            :label="item.paramValue"
            :value="item.paramValue"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班级" prop="classesId">
        <el-select v-model="formData.classesId">
          <el-option
            v-for="(item, index) in classesList"
            :key="index"
            :label="item.className"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirmHandle">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  props: {
    dialogData: {
      type: Object
    },
    title: {
      type: String
    }
  },
  data () {
    return {
      formData: {
        grade: new Date().getFullYear(),
        classesId: '',
        majorId: ''
      },
      gradeList: [],
      majorList: [],
      classesList: [],
      rules: {
        classesId: [{ required: true, message: '请选择班级', trigger: 'blur' }]
      }
    }
  },
  watch: {
    'dialogData.isOpen': function (isOpen) {
      if (isOpen) {
        this.formData.majorId = this.dialogData.majorId
        this.gradeList = this.$store.state.systemParam.grade
        this.getClassList()
        this.getMajorList()
      }
      if (!isOpen) {
        this.formData = {
          grade: new Date().getFullYear(),
          classesId: '',
          majorId: ''
        }
      }
    }
  },
  methods: {
    // 选择年级
    changeGrade () {
      this.formData.classesId = ''
      this.getClassList()
    },
    // 选择专业
    changeMajor () {
      this.formData.classesId = ''
      this.getClassList()
    },
    // 获取班级列表
    getClassList () {
      this.$http
        .getClassListQuery({
          grade: this.formData.grade,
          majorId: this.formData.majorId
        })
        .then((res) => {
          this.classesList = res.data
        })
    },
    getMajorList () {
      this.$http.getMajorListQuery({}).then((res) => {
        this.majorList = res.data
      })
    },
    // 确定事件
    confirmHandle () {
      this.$emit('confirm', this.formData)
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$confirm('确认关闭？')
        .then(() => {
          this.$emit('close')
        })
        .catch((_) => {})
    }
  }
}
</script>
