import Vue from 'vue'
import { cloneDeep, debounce, throttle, isEmpty } from 'lodash'

// 深拷贝
Vue.prototype.cloneDeep = cloneDeep
// 防抖
Vue.prototype.$debounce = debounce
// 节流
Vue.prototype.$throttle = throttle

// 是否为空
Vue.prototype.isEmpty = isEmpty
