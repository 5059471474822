/* 学生处 */
export const studentDepartmentRouter = [
  {
    path: '/studentDepartment/greenRoad',
    name: 'greenRoad',
    component: () => import('@/views/pages/studentDepartment/greenRoad.vue'),
    meta: {
      title: '绿色通道',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/uniformCard',
    name: 'uniformCard',
    component: () => import('@/views/pages/studentDepartment/uniformCard.vue'),
    meta: {
      title: '校服与校园卡登记',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/schoolCoatCount',
    name: 'schoolCoatCount',
    component: () => import('@/views/pages/studentDepartment/schoolCoatCount.vue'),
    meta: {
      title: '校服统计',
      role: ['超级管理员']
    }
  }
]
