import request from '@/service/request'

//  学年 page接口
export const pageGrade = (params) => {
  return request.post('/sch/grade/page', params)
}

// 获取学年列表
export const getGradeList = (params) => {
  return request.post('/sch/grade/page', params)
}

// 添加学年
export const addGrade = (params) => {
  return request.post('/sch/grade/save', params)
}

// 删除学年
export const deleteGrade = (params) => {
  return request.post('/sch/grade/del/' + params)
}

// 根据id获取学年信息
export const getGradeById = (params) => {
  return request.post('/sch/grade/getById/' + params)
}
