import request from '@/service/request'

// 获取学生列表
export const getStudentList = (params) => {
  return request.post('/stu/stuInfo/page', params)
}

// 添加学生
export const addStudent = (params) => {
  return request.post('/stu/stuInfo/save', params)
}

// 删除学生
export const deleteStudent = (params) => {
  return request.post('/stu/stuInfo/del/' + params)
}

// 根据【id】获取学生信息
export const getStudentById = (params) => {
  return request.post('/stu/stuInfo/getById/' + params)
}

// 根据【报到id】获取学生信息
export const getStudentBySignIdApi = (params) => {
  return request.post('/stu/stuInfo/getStuBySignInId', params)
}

// 根据userId获取学生信息
export const getStudentByUserId = (params) => {
  return request.post('/stu/stuInfo/getByUserId/' + params)
}

// 设置学籍状态
export const setStudentStatus = (params) => {
  return request.post('/stu/stuInfo/saveStuStatus', params)
}

/** 条件查询学生列表
 * @paramss {String} campusId 校区id
 * @paramss {String} collegeId 院系id
 * @paramss {String} majorId 专业id
 * @paramss {String} grade 年级
 * @paramss {String} classesId 班级id
 */
export const getStudentListQuery = (params) => {
  return request.post('/stu/stuInfo/list', params)
}

// 批量修改学生信息
/**
 * @paramss {Array} [{}] 学生信息
 */
export const updateStudentInfo = (params) => {
  return request.post('/stu/stuInfo/saveBatch', params)
}

// 修改学生照片
/**
 * @params {string} userId 学生id
 * @params {string} headshot 学生照片 (注意小写)
 */
export const updateStudentImageApi = (params) => {
  return request.post('/stu/stuInfo/updateStuHeadshotInfo', params)
}

// 上传学生学籍照片+报到
/**
 * @params {string} userId 学生id
 * @params {string} headshot 学生照片 (注意小写)
 */
export const stuSignInApi = (params) => {
  return request.post('/stu/stuInfo/handSignIn', params)
}

// 获取学生缴费信息
export const getStudentOrderList = (params) => {
  return request.post('/stu/stuInfo/pageForPayOrder', params)
}

// 获取报到统计
export const getStudentReportCount = (params) => {
  return request.post('/stu/stuInfo/countStuStatus', params)
}

// 修改专业、转专业
export const updateMajor = (params) => {
  return request.post('/stu/stuInfo/updateMajor', params)
}

// 校服统计
export const schoolCoatCount = (params) => {
  return request.post('/stu/stuInfo/schoolCoatCount', params)
}

// 清空学生班级
export const clearClassByStuId = (id) => {
  return request.post('/stu/stuInfo/clearClassByStuId/' + id, {})
}

// 修改学生记录（登记缴费）
export const saveForStuSignProcess = (params) => {
  return request.post('/stu/stuInfo/saveForStuSignProcess', params)
}

/* 修改学生住宿分（登记缴费）
* @paramss {number} params.roomPrice 费用
* @paramss {number} params.year 第几学年
* @paramss {string} params.userId 学生 userId
* */
export const updateRoomPriceByUserIdApi = (params) => {
  return request.post('/stu/stuInfo/updateRoomPrice', params)
}

// 学生报名流程
export const getStuSignProcess = (userId) => {
  return request.post(`/stu/stuSignProcess/getByUserId/${userId}`)
}
