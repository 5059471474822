<template>
  <div class="nav-tab-container">
    <div class="left-view">
      <el-tabs v-model="activeRouteInfo.title" type="card" closable @tab-remove="handleRemove" @tab-click="handleTabs">
        <el-tab-pane :key="item.title+''+index" v-for="(item, index) in tabRouteList" :label="item.title" :name="item.title">
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-tooltip class="item" effect="dark" content="关闭所有" placement="bottom">
      <span class="el-icon-close" @click="closeAllTabItem"></span>
    </el-tooltip>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapState('activeRouter', {
      tabRouteList: state => state.tabRouteList,
      activeRouteInfo: state => state.activeRouteInfo
    })
  },
  methods: {
    ...mapMutations('activeRouter', ['removeTabRoute', 'routeLink', 'closeAllTabRoute']),
    // 点击tab跳转路由
    handleTabs (tab) {
      this.routeLink(tab.name)
    },
    // 移除路由
    handleRemove (title) {
      this.removeTabRoute(title)
    },
    // 关闭所有路由
    closeAllTabItem () {
      this.closeAllTabRoute()
    }
  }
}
</script>

<style lang='scss' scoped>
.nav-tab-container {
  background-color: #fff;
  position: relative;
  .el-icon-close {
    position: absolute;
    top: 50%;
    right: -1px;
    height: 40px;
    width: 40px;
    background: #fff;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #E4E7ED;
    border-bottom: 1px solid #E4E7ED;
    /* border-right: 1px solid #E4E7ED; */
    transform: translate(-50%, -50%);
    cursor: pointer;
    &:hover {
      color: #409EFF;
    }
  }
  .left-view {
    box-shadow: 0 1px 2px #00152914;
  }
  ::v-deep .el-tabs__header {
    margin: 0;
    border-bottom: none;
  }
}
</style>
