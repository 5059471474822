<template>
  <div class="multiple-img-uploader">
    <!-- 上传 -->
    <el-upload
      v-if="urlList.length<8"
      ref="uploaderRef"
      :class="['avatar-uploader',{'is-disabled':disabled}]"
      :action="action"
      :headers="headers"
      :show-file-list="false"
      :on-success="onSuccess"
      :before-upload="beforeUpload"
      :on-exceed="handleExceed"
      :on-remove="remove"
      :limit="8"
      :disabled="urlList.length>=8?true: false"
      multiple
      :style="{width:width,height:height}">
      <i :class="['el-icon-plus','avatar-uploader-icon',{'is-disabled':urlList.length>=8}]"></i>
    </el-upload>
    <!-- 预览   -->
    <div class="image-preview-list mt-1" v-if="urlList.length">
      <div class="image-preview-box" v-for="(imgUrl, index) in urlList" :key="index"
           :style="{width:width,height:height}">
        <div class="del-shadow" @click="remove(imgUrl)">
          <i class="el-icon-delete"></i>
        </div>
        <!--        <div class='image'>-->
        <el-image
          :style="{width:width,height:height}" :src="$fileUrl + '' + imgUrl"
          :preview-src-list="[$fileUrl + '' + imgUrl]" fit="contain" />
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'multipleImgUploader',
  props: {
    urlList: {
      type: Array,
      default: () => ([])
    },
    width: {
      type: String,
      default: '120px'
    },
    height: {
      type: String,
      default: '120px'
    }
  },
  data () {
    return {
      headers: {
        Authorization: window.sessionStorage.getItem('token')
      },
      action: `${process.env.VUE_APP_API_URL}/auth/upload/image`,
      disabled: false
    }
  },
  methods: {
    // 删除图片
    remove (path) {
      const _imgList = this.urlList
      for (let i = 0; i < _imgList.length; i++) {
        if (_imgList[i] === path) {
          _imgList.splice(i, 1)
          break
        }
      }
    },
    // 上传前
    beforeUpload (file) {
      this.disabled = true
      const imgTypes = ['image/jpeg', 'image/jpg', 'image/png']
      const fileType = file.type
      const isImg = imgTypes.includes(fileType)
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isImg) {
        this.$message.error('仅支持jpg/jpeg/png文件')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传图片不能超过 5MB!')
        return false
      }
      return isImg && isLt2M
    },
    // 上传成功
    onSuccess (res) {
      if (res.success) {
        const _imgList = [...this.urlList]
        _imgList.push(res.data)
        this.$emit('update:urlList', _imgList)
        this.$emit('on-success')
      } else {
        this.$message.error({ message: '上传失败!' })
      }
    },
    handleExceed (files, fileList) {
      this.$message.error(`图片数量超出限制，仅支持最多上传8张照片，本次选择了 ${files.length} 张，共选择了${files.length + fileList.length}张。`)
    }
  }
}
</script>

<style lang="scss">
.multiple-img-uploader {
  .avatar-uploader {
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    color: $color-primary;
    font-size: 3rem;

    .el-upload {
      width: 100%;
      height: 100%;
      cursor: pointer;
      vertical-align: top;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      border-color: $color-primary;
    }

    .el-icon-plus.is-disabled {
      color: #898989;
      border-color: var(--border-light);

      &:hover {
        border-color: var(--border-light);
      }
    }
  }

  .image-preview-list {
  }

  .image-preview-box {
    display: inline-block;
    position: relative;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;

    & + .image-preview-box {
      margin-left: 10px;
    }

    .el-image-preview {
      height: auto;
    }

    .del-shadow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 16px;
      z-index: 9;
      background: rgba(0, 0, 0, .4);
      cursor: pointer;
      display: none;
      border-radius: 4px;

      .el-icon-delete {
        display: inline-block;
        height: auto;
        font-size: 2em;
      }
    }

    &:hover {
      .del-shadow {
        display: flex;

        &:hover {
          color: $color-danger;
        }
      }
    }
  }
}
</style>
