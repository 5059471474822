/* 课程管理 */
export const courseRouter = [
  {
    path: '/class/classApplicate',
    name: 'classApplicate',
    component: () => import('@/views/pages/class/classApplicate.vue'),
    meta: {
      title: '课程报名',
      role: ['超级管理员']
    }
  },
  {
    path: '/class/classPublish',
    name: 'classPublish',
    component: () => import('@/views/pages/class/classPublish.vue'),
    meta: {
      title: '课程发布',
      role: ['超级管理员']
    }
  }
]
