import { loginApi, getUserInfoApi } from '@/api/login'
import { systemParamRequest } from '@/api/publicRequest'

import { Message } from 'element-ui'
import router from '@/router/index'

const state = {
  token: window.sessionStorage.getItem('token'),
  roles: [],
  userInfo: {},
  isAdmin: false, // 用于判断是否是管理员，很多地方用到
  permissionTable: []
}

const mutations = {
  /* 添加token */
  addToken (state, token) {
    state.token = token
  },
  /* 添加角色 */
  addRole (state, userRole) {
    state.roles = userRole
  },
  // 添加权限
  addPermission (state, permission) {
    state.permissionTable = permission
  },
  /* 添加个人信息 */
  addUserInfo (state, userInfo) {
    state.userInfo = userInfo
  },
  /* 移除token */
  removeToken (state) {
    window.sessionStorage.clear()
    state.roles = []
    location.reload()
  }
}

const actions = {
  /* 用户登录 */
  login ({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      loginApi(userInfo).then(res => {
        commit('addToken', res.data)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  /* 获取用户个人信息 */
  getUserInfo ({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfoApi().then(res => {
        const roles = res.data.roleNameList
        if (roles.length === 0) {
          window.sessionStorage.clear()
          router.push('/login')
          Message.error({ message: '请分配角色' })
          return false
        } else if (roles.includes('维修中心') || roles.includes('访客') || roles.includes('学生') || roles.includes('学生精简版')) {
          // 特殊角色,不可登录系统,只能使用APP
          window.sessionStorage.clear()
          router.push('/login')
          Message.error({ message: '该账号不可登录后台管理系统!' })
          return false
        }
        commit('addRole', roles)
        commit('addPermission', res.data.paraNameList || [])
        commit('addUserInfo', res.data)
        state.isAdmin = roles.includes('超级管理员') || roles.includes('开发人员')
        window.sessionStorage.setItem('userInfo', JSON.stringify(res.data))
        resolve(roles)
        systemParamRequest()
      }).catch(err => {
        reject(err)
      })
    })
  },
  /* 退出登录 */
  logout ({ commit }) {
    commit('removeToken')
  }
}

const getters = {
  /* 获取token */
  getToken (state) {
    return state.token
  },
  /* 获取角色 */
  getRoles (state) {
    return state.roles
  },
  // 获取权限表
  getPermission (state) {
    return state.permissionTable
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
