/* 诊改信息管理与改进信息管理系统 */
export const diagnosisAndImprovementRouter = [
  {
    path: '/diagnosisAndImprovement/tree',
    name: 'Tree',
    component: () => import('@/views/pages/diagnosis-and-improvement/tree/tree.vue'),
    meta: {
      title: '诊断与改进项目树',
      role: ['超级管理员']
    }
  },
  {
    path: '/diagnosisAndImprovement/infoCollect/personCollectSummary',
    name: 'MemberInfoCollect',
    component: () => import('@/views/pages/diagnosis-and-improvement/infoCollect/personCollect/personCollectSummary.vue'),
    meta: {
      title: '人员教学信息采集情况',
      role: ['超级管理员']
    }
  },
  {
    path: '/diagnosisAndImprovement/infoCollect/personCollect',
    name: 'personCollect',
    component: () => import('@/views/pages/diagnosis-and-improvement/infoCollect/personCollect/personCollect.vue'),
    meta: {
      title: '人员教学信息采集',
      role: ['超级管理员']
    }
  },
  {
    path: '/diagnosisAndImprovement/infoCollect/infoCollect',
    name: 'infoCollect',
    component: () => import('@/views/pages/diagnosis-and-improvement/infoCollect/infoCollect/infoCollect.vue'),
    meta: {
      title: '基本信息采集',
      role: ['超级管理员']
    }
  },
  {
    path: '/diagnosisAndImprovement/infoCollect/rectification',
    name: 'Rectification',
    component: () => import('@/views/pages/diagnosis-and-improvement/infoCollect/rectification/rectification.vue'),
    meta: {
      title: '部门诊改信息采集情况',
      role: ['超级管理员']
    }
  },
  {
    path: '/diagnosisAndImprovement/infoCollect/rectificationSummary',
    name: 'RectificationSummary',
    component: () => import('@/views/pages/diagnosis-and-improvement/infoCollect/rectification/rectificationSummary.vue'),
    meta: {
      title: '部门诊改信息采集',
      role: ['超级管理员']
    }
  },
  {
    path: '/diagnosisAndImprovement/infoCollect/weeklyInfoCollect',
    name: 'WeeklyInfoCollect',
    component: () => import('@/views/pages/diagnosis-and-improvement/infoCollect/weeklyInfoCollect/weeklyInfoCollect.vue'),
    meta: {
      title: '每周信息采集',
      role: ['超级管理员']
    }
  },
  {
    path: '/diagnosisAndImprovement/infoCollect/weeklyInfoCollectSummary',
    name: 'WeeklyInfoCollectSummary',
    component: () => import('@/views/pages/diagnosis-and-improvement/infoCollect/weeklyInfoCollect/weeklyInfoCollectSummary.vue'),
    meta: {
      title: '每周信息采集情况',
      role: ['超级管理员']
    }
  },
  {
    path: '/diagnosisAndImprovement/achievement/subject',
    name: 'Subject',
    component: () => import('@/views/pages/diagnosis-and-improvement/achievement/subject/subject.vue'),
    meta: {
      title: '全校课题成果汇总',
      role: ['超级管理员']
    }
  },
  {
    path: '/diagnosisAndImprovement/achievement/patent',
    name: 'Patent',
    component: () => import('@/views/pages/diagnosis-and-improvement/achievement/patent/patent.vue'),
    meta: {
      title: '全校课题专利汇总',
      role: ['超级管理员']
    }
  },
  {
    path: '/diagnosisAndImprovement/achievement/thesis',
    name: 'Thesis',
    component: () => import('@/views/pages/diagnosis-and-improvement/achievement/thesis/thesis.vue'),
    meta: {
      title: '全校论文成果汇总',
      role: ['超级管理员']
    }
  },
  {
    path: '/diagnosisAndImprovement/achievement/competition',
    name: 'Competition',
    component: () => import('@/views/pages/diagnosis-and-improvement/achievement/competition/competition.vue'),
    meta: {
      title: '全校竞赛成果汇总',
      role: ['超级管理员']
    }
  }
]
