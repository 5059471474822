import request from '@/service/request'

// 获取老师列表
const getTeacherList = (params) => {
  return request.post('/stu/teacherInfo/page', params)
}

// 添加老师
const addTeacher = (params) => {
  return request.post('/stu/teacherInfo/save', params)
}

// 删除老师
const deleteTeacher = (params) => {
  return request.post('/stu/teacherInfo/del/' + params)
}

// 根据id获取老师信息
const getTeacherById = (params) => {
  return request.post('/stu/teacherInfo/getById/' + params)
}

// 条件查询教师
/**
 *
 * @param {String} campusId 校区
 * @param {String} classesId 班级
 * @param {String} collegeId 院系
 * @param {String} grade 年级
 * @returns
 */
const getTeacherListQuery = (params) => {
  return request.post('/stu/teacherInfo/list', params)
}

export {
  getTeacherList,
  addTeacher,
  deleteTeacher,
  getTeacherById,
  getTeacherListQuery
}
