import request from '@/service/request'

// 获取宿舍缴费列表
const getDormOrderList = (params) => {
  return request.post('/stu/stuInfo/pageForDormitoryPayOrder', params)
}

// 添加宿舍缴费
const addDormOrder = (params) => {
  return request.post('/stu/stuDormitoryOrder/save', params)
}

// 删除宿舍缴费
const deleteDormOrder = (params) => {
  return request.post('/stu/stuDormitoryOrder/del/' + params)
}

// 根据id获取宿舍缴费信息
const getDormOrderById = (params) => {
  return request.post('/stu/stuDormitoryOrder/getById/' + params)
}

// 条件查询全部宿舍缴费订单
const getDormOrderListQuery = (params) => {
  return request.post('/stu/stuDormitoryOrder/list', params)
}

// 宿舍缴费统计
const getDormOrderCount = (params) => {
  return request.post('/stu/stuDormitoryOrder/count', params)
}

export {
  getDormOrderList,
  addDormOrder,
  deleteDormOrder,
  getDormOrderById,
  getDormOrderListQuery,
  getDormOrderCount
}
