/* 用电管理 */
export const electricManageRouter = [
  {
    path: '/repair/freeElectric',
    name: 'freeElectric',
    component: () => import('@/views/pages/electric-manage/free-electric/freeElectric.vue'),
    meta: {
      title: '每月电费赠送',
      role: ['超级管理员']
    }
  },
  {
    path: '/repair/electricMeterManage',
    name: 'electricMeterManage',
    component: () => import('@/views/pages/dormManage/dormRoom.vue'),
    meta: {
      title: '水电表管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/repair/electricMeterClearRecord',
    name: 'electricMeterClearRecord',
    component: () => import('@/views/pages/electric-manage/electric-meter-clear-record/electricMeterClearRecord.vue'),
    meta: {
      title: '电表清空记录',
      role: ['超级管理员']
    }
  }
]
