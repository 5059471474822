<template>
  <el-dialog
    class="bind-teacher-dialog"
    title="绑定辅导员"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="540px"
    :before-close="cancel"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="所有部门">
        <el-select v-model="formData.deptId" @change="changeDepartment">
          <el-option
            v-for="{deptName,id} in departmentList"
            :key="id"
            :label="deptName"
            :value="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="选择教师" prop="managerUserId">
        <el-select v-model="formData.managerUserId">
          <el-option
            v-for="{teacherName, userId} in teacherList"
            :key="userId"
            :label="teacherName"
            :value="userId"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag"/>
      <el-button type="primary" @click="save('绑定辅导员',addClasses,'绑定成功')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { addClasses } from '@/api/classes'

export default {
  name: 'baseBindTeacher',
  mixins: [dialog],
  data () {
    return {
      formData: {
        deptId: '',
        managerUserId: ''
      },
      departmentList: [],
      teacherList: [],
      rules: {
        managerUserId: [{ required: true, message: '请选择辅导员', trigger: 'blur' }]
      }
    }
  },
  mounted () {
    this.getDepartmentList()
    this.$nextTick(() => {

    })
  },
  methods: {
    addClasses, // 保存 api
    // 获取部门
    getDepartmentList () {
      this.$http.getDepartmentListQuery({}).then((res) => {
        this.departmentList = res.data
      })
    },
    // 根据获取教师
    getTeacherList (deptId) {
      this.$http
        .getTeacherListQuery({
          deptId: deptId
        })
        .then((res) => {
          this.teacherList = res.data
        })
    },
    // 选择部门
    changeDepartment (deptId) {
      this.formData.managerUserId = ''
      this.getTeacherList(deptId)
    }
  }
}
</script>
