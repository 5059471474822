<template>
  <div class='headerSelect'>
    <el-row>
      <el-col :span='24'>
        <el-form ref='form' :model='formData' :inline='true' class='demo-form-inline'>
          <el-form-item
            label='全日制专业'
            v-if="isSelectItem('partTimeFlag')">
            <el-select v-model='formData.partTimeFlag' size='mini' @change='onChangePartTimeFlag'>
              <el-option :value='0' label='全日制' />
              <el-option :value='1' label='非全日制' />
            </el-select>
          </el-form-item>
          <el-form-item
            label='是否转过专业'
            v-if="isSelectItem('changeMajorFlag')"
          >
            <el-select v-model='formData.changeMajorFlag' size='mini' clearable>
              <el-option value='0' label='否' />
              <el-option value='1' label='是' />
            </el-select>
          </el-form-item>
          <el-form-item label='年级' v-if="isSelectItem('grade')">
            <el-select
              v-model='formData.grade'
              :clearable='isGradeClearable'
              filterable
              @change="selectHandle('grade')"
              size='mini'>
              <el-option
                v-for='{paramValue} in gradeList'
                :key='paramValue'
                :label='paramValue'
                :value='paramValue' />
            </el-select>
          </el-form-item>
          <el-form-item label='学年' v-if="isSelectItem('year')">
            <el-select v-model='formData.year' size='mini' clearable>
              <el-option value='1' label='1' />
              <el-option value='2' label='2' />
              <el-option value='3' label='3' />
            </el-select>
          </el-form-item>
          <el-form-item label='校区' v-if="isSelectItem('campus')">
            <el-select
              v-model='formData.campusId'
              clearable
              filterable
              @change="selectHandle('campus')"
              size='mini'
            >
              <el-option
                v-for='(item, index) in campusList'
                :key='index'
                :label='item.campusName'
                :value='item.id' />
            </el-select>
          </el-form-item>
          <el-form-item label='部门' v-if="isSelectItem('department')">
            <el-select
              v-model='formData.deptId'
              clearable
              filterable
              @change="selectHandle('department')"
              size='mini'
            >
              <el-option
                v-for='(item, index) in departmentList'
                :key='index'
                :label='item.deptName'
                :value='item.id' />
            </el-select>
          </el-form-item>
          <el-form-item label='院系' v-if="isSelectItem('college')">
            <el-select
              ref='collegeRef'
              v-model='formData.collegeId'
              clearable
              filterable
              @change="selectHandle('college')"
              size='mini'
              :disabled='disableObj.college'
            >
              <el-option
                v-for='{collegeName,id} in collegeList'
                :key='id'
                :label='collegeName'
                :value='id'/>
            </el-select>
          </el-form-item>
          <el-form-item label='专业' v-if="isSelectItem('major')">
            <el-select
              ref='majorRef'
              v-model='formData.majorId'
              clearable
              filterable
              @change="selectHandle('major')"
              size='mini'
            >
              <el-option
                v-for='(item, index) in majorList'
                :key='index'
                :label='item.majorName'
                :value='item.id' />
            </el-select>
          </el-form-item>
          <el-form-item label='班级' v-if="isSelectItem('class')">
            <el-select
              v-model='formData.classesId'
              clearable
              filterable
              @change="selectHandle('class')"
              size='mini'
            >
              <el-option
                v-for='(item, index) in classList'
                :key='index'
                :label='item.className'
                :value='item.id' />
            </el-select>
          </el-form-item>
          <el-form-item
            label='是否有班级'
            v-if="isSelectItem('classesNoNullFlag')"
          >
            <el-select
              v-model='formData.classesNoNullFlag'
              size='mini'
              clearable
            >
              <el-option value='0' label='否'></el-option>
              <el-option value='1' label='是'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label='性别' v-if="isSelectItem('sexType')">
            <el-select v-model='formData.sexType' size='mini' clearable>
              <el-option value='1' label='男'></el-option>
              <el-option value='2' label='女'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label='学籍状态' v-if="isSelectItem('stuStatus')">
            <el-select
              ref='stuStatusRef'
              v-model='formData.stuStatus'
              clearable
              filterable
              @change="selectHandle('stuStatus')"
              size='mini'>
              <el-option
                v-for='(item, index) in stuStatus'
                :key='index'
                :label='item.paramName'
                :value='Number(item.paramValue)' />
            </el-select>
          </el-form-item>
          <slot/>
          <el-form-item v-if='isSearchButton'>
            <el-button size='mini' icon='el-icon-search' @click='searchHandle'>查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  /**
   * @description 用于头部下拉过滤选择
   * @property {Array} selectItem 显示选择器
   * @property {Array} selectItemAll 是否显示所有选项
   * @event change 选择事件
   */
  props: {
    selectItem: {
      type: Array,
      default: () => {
        return ['grade', 'campus', 'college', 'major']
      }
    },
    selectItemAll: {
      type: Array,
      default: () => {
        return ['campus']
      }
    },
    selectDefault: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectStustatus: {
      type: Number,
      default: 1 // 在读
    },
    isSearchButton: {
      type: Boolean,
      default: true
    },
    stuStatusList: {
      type: Array,
      default: () => {
        return []
      }
    },
    isGradeClearable: { // 年级是否可清空
      type: Boolean,
      default: true
    },
    formLabels: { // select选中后的label
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      formData: {
        changeMajorFlag: '',
        partTimeFlag: 0,
        classesNoNullFlag: '',
        sexType: '',
        year: '1',
        campusId: '',
        deptId: '',
        collegeId: '',
        majorId: '',
        grade: new Date().getFullYear(),
        stuStatus: this.selectStustatus,
        classesId: ''
      },
      initQuery: {},
      formNames: { // 批量操作时,获取品类名
        collegeName: '',
        majorName: '',
        classesName: '',
        grade: '',
        stuStatusName: ''
      },
      campusList: [],
      gradeList: [],
      departmentList: [],
      collegeList: [],
      majorList: [],
      classList: [],
      stuStatus: [],
      disableObj: {
        college: false
      }
    }
  },
  computed: {
    isSelectItem () {
      return (item) => {
        return this.selectItem.includes(item)
      }
    }
  },
  mounted () {
    if (this.selectItem.includes('grade')) {
      this.getGradeList()
    }
    if (this.selectItem.includes('campus')) {
      this.getCampusList()
    }
    if (this.selectItem.includes('department')) {
      this.getDepartment()
    }
    if (this.selectItem.includes('college')) {
      const collegeId = JSON.parse(
        window.sessionStorage.getItem('userInfo')
      ).collegeId
      if (this.selectDefault.includes('college') && collegeId) {
        this.formData.collegeId = collegeId
        this.disableObj.college = true
      }
      this.getCollegeList()
    }
    if (this.selectItem.includes('major')) {
      this.getMajorList()
    }
    if (this.selectItem.includes('stuStatus')) {
      this.getStuStatus()
    }
    this.setInitQuery()
    this.$emit('search', this.formData)
  },
  methods: {
    /* select 选中事件
    *  院系/专业 加载所属的专业list/班级list,获取选中的label名
    *  其他类型获取选中的label名 .$refs.selectedLabel
    *  获取label后需要通知父级 emit (formNames) 更新获取的信息
    * */
    selectHandle (type, data) {
      this.$nextTick(() => {
        if (type === 'major') {
          this.formNames.majorName = this.$refs.majorRef.selectedLabel
          this.formData.classesId = ''
          this.getClassList()
        } else if (type === 'college') {
          this.formNames.collegeName = this.$refs.collegeRef.selectedLabel
          this.formData.majorId = ''
          this.getMajorList()
        } else if (type === 'campus') {
          this.formData.collegeId = ''
          this.formData.majorId = ''
          this.formData.classesId = ''
          this.getCollegeList()
        } else if (type === 'grade') {
          this.formNames.grade = `${this.formData.grade}届`
        } else if (type === 'stuStatus') {
          this.formNames.stuStatusName = this.$refs.stuStatusRef.selectedLabel
        }
        this.$emit('change', this.formData)
        this.$emit('update:formLabels', this.formNames)
      })
    },
    // 查询事件
    searchHandle () {
      const classItem = this.classList.filter(
        (item) => item.id === this.formData.classesId
      )[0]
      this.$emit('search', {
        ...this.formData,
        classItem
      })
    },
    // 获取年级
    getGradeList () {
      const grade = this.$store.state.systemParam.grade
      if (grade.length === 0) {
        this.$http.getSysDictionaryByKey('grade').then((res) => {
          this.gradeList = res.data
        })
      }
      if (this.selectItemAll.includes('grade')) {
        this.gradeList = [{ paramValue: '所有年级' }, ...grade]
      } else {
        this.gradeList = grade
      }
    },
    // 获取校区
    getCampusList () {
      this.$http.getCampusListQuery({}).then((res) => {
        if (this.selectItemAll.includes('campus')) {
          this.campusList = [
            {
              id: '',
              campusName: '所有校区'
            },
            ...res.data
          ]
        } else {
          this.campusList = res.data
        }
      })
    },
    // 获取部门
    getDepartment () {
      this.$http.getDepartmentListQuery({}).then((res) => {
        if (this.selectItemAll.includes('department')) {
          this.departmentList = [
            {
              id: '',
              deptName: '所有部门'
            },
            ...res.data
          ]
        } else {
          this.departmentList = res.data
        }
      })
    },
    // 获取院系
    getCollegeList () {
      this.$http
        .getCollegeListQuery({
          campusId: this.formData.campusId
        })
        .then((res) => {
          if (this.selectItemAll.includes('college')) {
            this.collegeList = [
              {
                id: '',
                collegeName: '所有系部'
              },
              ...res.data
            ]
          } else {
            this.collegeList = res.data
          }
        })
    },
    // 获取专业
    getMajorList (partTimeFlag) {
      this.$http
        .getMajorListQuery({
          collegeId: this.formData.collegeId,
          partTimeFlag: partTimeFlag
        })
        .then((res) => {
          if (this.selectItemAll.includes('major')) {
            this.majorList = [
              {
                id: '',
                majorName: '所有专业'
              },
              ...res.data
            ]
          } else {
            this.majorList = res.data
          }
        })
    },
    // 获取班级
    getClassList () {
      this.$http
        .getClassListQuery({
          majorId: this.formData.majorId
        })
        .then((res) => {
          if (this.selectItemAll.includes('class')) {
            this.majorList = [
              {
                id: '',
                majorName: '所有班级'
              },
              ...res.data
            ]
          } else {
            this.classList = res.data
          }
        })
    },
    // 获取学籍状态
    getStuStatus () {
      this.stuStatus = this.$store.state.systemParam.stuStatus
      if (this.stuStatus.length === 0) {
        this.$http.getSysDictionaryByKey('stuStatus').then((res) => {
          this.stuStatus = res.data
        })
      }
      if (this.stuStatusList.length > 0) {
        const filterStatus = [...this.stuStatus].filter((x) =>
          [...this.stuStatusList].some((y) => Number(x.paramValue) === y)
        )
        this.stuStatus = filterStatus
      } else {
        this.formData.stuStatus = null
      }
    },
    onChangePartTimeFlag (value) {
      this.getMajorList(value)
    },
    // 记录初始数据,用于重置搜索条的操作
    setInitQuery () {
      this.initQuery = cloneDeep(this.formData)
    },
    // 重置搜索条
    clear () {
      Object.keys(this.initQuery).map(key => {
        this.formData[key] = this.initQuery[key]
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.headerSelect {
  .el-form {
    padding: 0;

    .el-form-item {
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
}
</style>
