/* 二级学院 */
export const secondCollegeRouter = [
  {
    path: '/secondCollege/leaveOut',
    name: 'leaveOut',
    component: () => import('@/views/pages/secondCollege/leave-out/leaveOut'),
    meta: {
      title: '外出请假',
      role: ['超级管理员']
    }
  },
  {
    path: '/secondCollege/studentSignIn',
    name: 'studentSignIn',
    component: () => import('@/views/pages/secondCollege/studentSignIn.vue'),
    meta: {
      title: '学生注册',
      role: ['超级管理员']
    }
  },
  {
    path: '/secondCollege/studentCheckIn',
    name: 'studentCheckIn',
    component: () => import('@/views/pages/dormManage/dorm-room-bed/dormRoomBed.vue'),
    meta: {
      title: '学生入住',
      role: ['超级管理员']
    }
  },
  {
    path: '/secondCollege/classManage',
    name: 'classManage',
    component: () => import('@/views/pages/secondCollege/classManage.vue'),
    meta: {
      title: '班级管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/secondCollege/classCategory',
    name: 'classCategory',
    component: () => import('@/views/pages/secondCollege/classCategory.vue'),
    meta: {
      title: '分班管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/secondCollege/classStudent',
    name: 'classStudent',
    component: () => import('@/views/pages/secondCollege/classStudent.vue'),
    meta: {
      title: '班级花名册',
      role: ['超级管理员']
    }
  },
  {
    path: '/secondCollege/setDorm',
    name: 'setDorm',
    component: () => import('@/views/pages/dormManage/dormRoom.vue'),
    meta: {
      title: '宿舍设置',
      role: ['超级管理员']
    }
  }
]
