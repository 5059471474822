import request from '@/service/request'
// 获取学生三年的住宿费用
export const getStuRoomPrices = (params) => {
  return request.post('/stu/stuTuitionOrder/stuRoomPrices/' + params)
}

// 获取缴费列表
export const getStudentOrderListApi = (params) => {
  return request.post('/stu/stuTuitionOrder/page', params)
}

// 添加缴费
export const addStudentOrderApi = (params) => {
  return request.post('/stu/stuTuitionOrder/save', params)
}

/* 修改备注(缴费详情模块)
* @params {string} id
* @params {string} remarks
* */
export const saveStudentOrderRemarksApi = (params) => {
  return request.post('/stu/stuTuitionOrder/saveRemarks', params)
}

// 删除缴费
export const deleteStudentOrderApi = (params) => {
  return request.post('/stu/stuTuitionOrder/del/' + params)
}

/* 撤销缴费订单
*  @param {string} id
* */
export const undoStudentOrderApi = (id) => {
  return request.post(`/stu/stuTuitionOrder/undo/${id}`)
}

// 根据id获取缴费信息
export const getStudentOrderByIdApi = (params) => {
  return request.post('/stu/stuTuitionOrder/getById/' + params)
}

// 条件查询全部缴费订单
export const getStudentOrderListQueryApi = (params) => {
  return request.post('/stu/stuTuitionOrder/list', params)
}

// 学费缴费统计
export const getStudentOrderCountApi = (params) => {
  return request.post('/stu/stuTuitionOrder/count', params)
}

// 订单查询
export const getPayOrderListApi = (params) => {
  return request.post('/pay/payOrder/page', params)
}

// 订单查询
export const getTuitionOrderOrderListApi = (params) => {
  return request.post('/stu/stuTuitionOrder/pageOrder', params)
}

// 中行订单查询
export const getBocOrderOrderListApi = (params) => {
  return request.post('/pay/payBocOrder/page', params)
}

// 工行订单查询
export const getIcbcOrderOrderListApi = (params) => {
  return request.post('/pay/payIcbcOrder/page', params)
}

// 统一订单查询
export const pageUnionOrderRecordApi = (params) => {
  return request.post('/pay/payOrder/pageUni', params)
}

// 统一订单查询 统计数据
export const summaryUnionOrderRecordApi = (params) => {
  return request.post('/pay/payOrder/countUniSuccessPay', params)
}
