<template>
  <div v-permission="permitKey" style="display: inline-block;white-space: nowrap">
    <el-button
      size="small"
      icon="el-icon-download"
      @click="$emit('on-download')"
    >{{ downloadTitle }}
    </el-button>
    <el-upload
      class="upload-excel"
      ref="importRef"
      action=""
      :disabled="disabled"
      accept=".xls,.xlsx,.csv"
      :on-change="uploadFile"
      :auto-upload="false"
      :show-file-list="false">
      <el-button :disabled="btnDisabled" :icon="btnDisabled?'el-icon-loading':icon" :type="importBtnType"
                 :plain="importBtnType!=='success'"
                 size="small">{{ importTitle }}
      </el-button>
    </el-upload>
  </div>
</template>

<script>
import { throttle } from 'lodash'

export default {
  name: 'uploadExcel',
  props: {
    permitKey: {
      type: Array,
      default: () => ([])
    }, // 权限key
    api: Function, // 导入链接
    query: String, // 导入参数
    downloadApi: Function, // 下载导入模板链接
    loading: {
      type: Boolean,
      default: false
    },
    errMsg: {
      type: String,
      default: '导入失败，请注意数据格式'
    },
    downloadTitle: {
      type: String,
      default: '下载导入模板'
    },
    importTitle: {
      type: String,
      default: '批量导入'
    },
    icon: { // 导入图标
      type: String,
      default: 'el-icon-upload2'
    },
    importBtnType: { // 导入按钮样式
      type: String,
      default: 'primary'
    }
  },
  data() {
    return {
      disabled: false,
      btnDisabled: false,
      uploadFlag: true
    }
  },
  methods: {
    remove() {
      this.$refs.importRef.clearFiles()
      this.disabled = this.btnDisabled = false
    },
    beforeUpload(file) {
      this.disabled = this.btnDisabled = true
      const xlsTypes = ['xls', 'xlsx', 'csv']
      const fileType = file.name.split('.')[1]
      const isXls = xlsTypes.includes(fileType)
      if (!isXls) {
        this.$message.error('仅支持.xls/.xlsx/.csv文件')
        this.remove()
        this.uploadFlag = this.disabled = this.btnDisabled = false
        return false
      }
    },
    uploadFile: throttle(async function(file) {
      this.beforeUpload(file)
      if (!this.uploadFlag) return
      this.$emit('update:loading', true)
      const _excelData = new FormData()
      _excelData.append('file', file.raw)
      try {
        const res = await this.api(_excelData, this.query)
        if (res.data.data) { // 通过res.data 是否>0判断是否导入成功
          this.$message.success(`已成功导入${res.data.data}条数据！`)
          this.$emit('on-success')
        } else {
          this.$confirm(`<p style="white-space:normal;font-size: 14px;word-break: break-all;line-height: 2;color:var(--color-danger)">${res.data.msg || this.errMsg}</p>`, '导入失败', {
            dangerouslyUseHTMLString: true
          }).then()
        }
      } catch (e) {
        return e
      } finally {
        this.$emit('update:loading', false)
        this.remove()
      }
    }, 1500, {})
  }
}
</script>
<style lang="scss">
.upload-excel {
  display: inline-block;
}
</style>
