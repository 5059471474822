import { dormRouter } from '@/router/dorm-router'
import { financeManageRouter } from '@/router/finance-manage-router'
import { baseInfoRouter } from '@/router/base-info-router'
import { educationRouter } from '@/router/education-router'
import { stuInfoPartTimeRouter } from '@/router/stu-info-part-time-router'
import { appManageRouter } from '@/router/app-manage-router'
import { teachBuildRouter } from '@/router/teach-build-router'
import { courseRouter } from '@/router/course-router'
import { principalOfficeRouter } from '@/router/principal-office-router'
import { secondCollegeRouter } from '@/router/second-college-router'
import { recruitManageRouter } from '@/router/recruit-manage-router'
import { studentDepartmentRouter } from '@/router/student-department-router'
import { systemRouter } from '@/router/system-router'
import { repairRouter } from '@/router/repair-router'
import { electricManageRouter } from '@/router/electric-manage-router'
import { waterManageRouter } from '@/router/water-manage-router'
import { diagnosisAndImprovementRouter } from '@/router/diagnosis-and-improvement-router' //诊断与改进
import { contractRouter } from '@/router/contract' //合同审核
import { hrDepartmentRouter } from '@/router/hr-department-router'

/* 动态路由表 */
/* role对应路由的角色权限 */
const asyncRouter = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/pages/index/index.vue'),
    meta: {
      title: '首页',
      role: ['超级管理员']
    }
  },
  {
    path: '/lock/list',
    name: 'lockRecord',
    component: () => import('@/views/pages/lock/list'),
    meta: {
      title: '开锁记录',
      role: ['超级管理员']
    }
  },
  ...financeManageRouter,
  ...dormRouter,
  ...baseInfoRouter,
  ...educationRouter,
  ...stuInfoPartTimeRouter,
  ...appManageRouter,
  ...teachBuildRouter,
  ...courseRouter,
  ...principalOfficeRouter,
  ...secondCollegeRouter,
  ...recruitManageRouter,
  ...studentDepartmentRouter,
  ...systemRouter,
  ...repairRouter,
  ...electricManageRouter,
  ...diagnosisAndImprovementRouter,
  ...contractRouter,
  ...hrDepartmentRouter,
  ...waterManageRouter,
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/components/404/index.vue')
  }
]

export default asyncRouter
