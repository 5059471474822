/**
 * 基于用户角色过滤路由
 */
import routeTable from '@/router/routeTable'
import Index from '@/views/home/index.vue'

function filterRouteTable (roles, route) {
  if (route.meta && route.meta.role) {
    return roles.some(role => route.meta.role.indexOf(role) >= 0)
  } else {
    return true
  }
}

const state = {
  filterRoute: []
}

const mutations = {
  /* 添加路由表 */
  addRouteTable (state, filterRoute) {
    state.filterRoute = [
      {
        path: '/index',
        name: 'Index',
        redirect: '/home',
        component: Index,
        meta: {
          title: '首页'
        },
        children: filterRoute
      }
    ]
  },
  /* 移除路由表 */
  removeRouteTable (state) {
    state.filterRoute = []
  }
}
const actions = {
  /* 获取用户角色 */
  getUserRole ({ commit }, roles) {
    return new Promise(resolve => {
      const filterRoute = routeTable.filter(item => {
        /* 判断角色之中是否有超级管理员 */
        if (roles.length > 0) return true // 去掉角色判断
        if (roles.indexOf('超级管理员') >= 0) return true
        if (filterRouteTable(roles, item)) {
          if (item.children && item.children.length > 0) {
            item.children = item.children.filter(child => {
              if (filterRouteTable(roles, child)) {
                return child
              }
              return false
            })
            return item
          } else {
            return item
          }
        }
        return false
      })
      commit('addRouteTable', filterRoute)
      resolve()
    })
  }
}

const getters = {
  /* 获取路由表 */
  getRouteTable (state) {
    return state.filterRoute
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
