import Vue from 'vue'
import { exportExcelHttp, importExcelHttp, exportWordHttp } from './excel'
import { getStudentListQuery, updateStudentInfo } from './student'
import { getTeacherListQuery } from './teacher'
import { getCampusListQuery } from './campus'
import { getCollegeListQuery } from './college'
import { getClassListQuery } from './classes'
import { getMajorListQuery, getMajorById } from './major'
import { getPriceListQuery } from './price'
import { getSysParamsByKey, getSysDictionaryByKey } from './sysParams'
import { getDormTree, getDormRoomTypeListQuery, getDormRoomQuery, getDormBuildQuery, getDormRoomBedListQuery } from './dorm'
import { getStudentOrderListQueryApi } from './finance-manage/studentOrder'
import { getDormOrderListQuery } from './dormOrder'
import { getDepartmentListQuery } from './department'
// 获取系统参数 赋值array
import { getParamListByKey, getParamName } from '@/utils/common-js'

/* 全局挂载方法 */
Vue.prototype.$http = {
  exportExcelHttp, // 导出excel
  importExcelHttp, // 导入excel
  exportWordHttp, // 导出word
  getStudentListQuery, // 条件查询学生
  updateStudentInfo, // 批量更新学生信息
  getCampusListQuery, // 条件查询校区
  getCollegeListQuery, // 条件查询院系
  getMajorListQuery, // 条件查询专业
  getClassListQuery, // 条件查询班级
  getPriceListQuery, // 条件查询年度费用
  getDormRoomTypeListQuery, // 条件查询房间类型
  getSysParamsByKey, // 根据key查询系统参数
  getSysDictionaryByKey, // 根据key查询系统字典
  getDormTree, // 获取宿舍楼层数
  getDormRoomQuery, // 条件查询宿舍
  getDormBuildQuery, // 条件查询楼栋
  getDormRoomBedListQuery, // 条件查询床位
  getTeacherListQuery, // 条件查询教师
  getStudentOrderListQueryApi, // 条件查询学生年度缴费订单
  getDormOrderListQuery, // 条件查询宿舍缴费订单
  getMajorById, // 根据id获取专业
  getDepartmentListQuery, // 条件查询部门
  getParamListByKey, // 根据参数键获取系统参数列表
  getParamName // 获取系统名
}
