import router from '@/router/index'
import { cloneDeep } from 'lodash'

const s1 = JSON.parse(window.sessionStorage.getItem('tabRouteList'))
const s2 = JSON.parse(window.sessionStorage.getItem('activeRouteInfo'))

const state = {
  tabRouteList: s1 || [
    {
      title: '首页',
      path: '/home',
      default: true
    }
  ],
  activeRouteInfo: s2 || {
    title: '首页',
    default: true,
    path: '/home'
  }
}

const mutations = {
  // 添加tab路由
  addTabRoute (state, route) {
    const info = {
      parentName: route.parent.title,
      parentPath: route.parent.path,
      ...route.children
    }
    state.activeRouteInfo = cloneDeep(info)
    window.sessionStorage.setItem('activeRouteInfo', JSON.stringify(info))
    state.activeTab = cloneDeep(info)
    const index = state.tabRouteList.findIndex(item => item.id === info.id)
    if (index === -1) {
      state.tabRouteList.push(JSON.parse(JSON.stringify(info)))
    }
    window.sessionStorage.setItem('tabRouteList', JSON.stringify(state.tabRouteList))
  },
  // 移除tab路由
  removeTabRoute (state, title) {
    const list = state.tabRouteList.filter(item => item.title !== title)
    const index = state.tabRouteList.findIndex(item => item.title === title)
    if (index === 0) return
    state.tabRouteList = cloneDeep(list)
    window.sessionStorage.setItem('tabRouteList', JSON.stringify(state.tabRouteList))
    if (index === list.length) {
      const active = state.tabRouteList[index - 1]
      state.activeRouteInfo = JSON.parse(JSON.stringify(active))
      window.sessionStorage.setItem('activeRouteInfo', JSON.stringify(active))
      if (state.activeRouteInfo.default) {
        router.push(`${state.activeRouteInfo.path}`)
      } else {
        router.push(`${state.activeRouteInfo.parentPath}${state.activeRouteInfo.path}`)
      }
    }
    if (title === state.activeRouteInfo.title) {
      const lastValue = state.tabRouteList.slice(-1)[0]
      state.activeRouteInfo = cloneDeep(lastValue)
      window.sessionStorage.setItem('activeRouteInfo', JSON.stringify(lastValue))
      router.push(`${state.activeRouteInfo.parentPath}${state.activeRouteInfo.path}`)
    }
  },
  // 点击tab路由跳转
  routeLink (state, title) {
    const index = state.tabRouteList.findIndex(item => item.title === title)
    const activeItem = state.tabRouteList[index]
    state.activeRouteInfo = cloneDeep(activeItem)
    window.sessionStorage.setItem('activeRouteInfo', JSON.stringify(activeItem))
    if (state.activeRouteInfo.default) {
      router.push(`${state.activeRouteInfo.path}`)
    } else {
      router.push(`${state.activeRouteInfo.parentPath}${state.activeRouteInfo.path}`)
    }
  },
  // 关闭所有
  closeAllTabRoute (state) {
    state.tabRouteList = [
      {
        title: '首页',
        path: '/home',
        default: true
      }
    ]
    state.activeRouteInfo = {
      title: '首页',
      path: '/home',
      default: true
    }
    window.sessionStorage.setItem('tabRouteList', JSON.stringify(state.tabRouteList))
    window.sessionStorage.setItem('activeRouteInfo', JSON.stringify(state.activeRouteInfo))
    router.push(`${state.activeRouteInfo.path}`)
  },
  // 设置默认激活路由
  setDefaultRoute (state) {
    state.activeRouteInfo = JSON.parse(JSON.stringify(state.tabRouteList[0]))
    window.sessionStorage.setItem('activeRouteInfo', JSON.stringify(state.tabRouteList[0]))
  }
}

const actions = {}

const gettres = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  gettres
}
