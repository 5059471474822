/* 用水管理 */
export const waterManageRouter = [
  {
    path: '/repair/waterByTeacher',
    name: 'waterByTeacher',
    component: () => import('@/views/pages/waterManage/waterByTeacher.vue'),
    meta: {
      title: '教师用水',
      role: ['超级管理员']
    }
  },
  {
    path: '/repair/waterByStu',
    name: 'waterByStu',
    component: () => import('@/views/pages/waterManage/waterByStu.vue'),
    meta: {
      title: '学生用水',
      role: ['超级管理员']
    }
  }
]
