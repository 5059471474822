/* 财务处 router */
export const financeManageRouter = [
  /* 财务处 */
  {
    path: '/financeManage/studentOrder',
    name: 'studentOrder',
    component: () => import('@/views/pages/financeManage/student-order/studentOrder.vue'),
    meta: {
      title: '学费缴费登记',
      role: ['超级管理员']
    }
  },
  {
    path: '/financeManage/studentOrderCount',
    name: 'studentOrderCount',
    component: () => import('@/views/pages/financeManage/studentOrderCount.vue'),
    meta: {
      title: '学费缴费统计',
      role: ['超级管理员']
    }
  },
  {
    path: '/financeManage/searchOrder',
    name: 'searchOrder',
    component: () => import('@/views/pages/financeManage/searchOrder/searchOrder.vue'),
    meta: {
      title: '订单查询',
      role: ['超级管理员']
    }
  },
  {
    path: '/financeManage/unionOrderRecord',
    name: 'unionOrderRecord',
    component: () => import('@/views/pages/financeManage/union-order-record/unionOrderRecord.vue'),
    meta: {
      title: '统一订单查询',
      role: ['超级管理员']
    }
  },
  {
    path: '/financeManage/chargingFeeRecord',
    name: 'ChargingFeeRecord',
    component: () => import('@/views/pages/financeManage/chargingFeeRecord/chargingFeeRecord.vue'),
    meta: {
      title: '电费记录',
      role: ['超级管理员']
    }
  },
  {
    path: '/financeManage/batchPayModel',
    name: 'batchPayModel',
    component: () => import('@/views/pages/financeManage/batch-pay-model/batchPayModel.vue'),
    meta: {
      title: '自定义缴费批次',
      role: ['超级管理员']
    }
  }
]
