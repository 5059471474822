//  通用的表单校验
import {
  validate20WordLimit,
  validate30WordLimit,
  validateNumber,
  validateTwoDecimalPlaces,
  validatePhone,
  validateNegativeNumberWithTwoDecimalPlaces
} from '@/utils/form-validate'

const state = {
  //  限制30字
  limit30WordsObj: { validator: validate30WordLimit, trigger: 'blur' },
  //  限制20字
  limit20WordsObj: { validator: validate20WordLimit, trigger: 'blur' },
  // 电话号校验
  validatorPhoneObj: { validator: validatePhone, trigger: 'blur' },
  // 数字校验
  validatorNumberObj: { validator: validateNumber, trigger: 'blur' },
  // 两位小数校验
  validatorTwoDecimalPlacesObj: { validator: validateTwoDecimalPlaces, trigger: 'blur' },
  // 负数+两位小数校验
  validateNegativeNumberWithTwoDecimalPlaces: { validator: validateNegativeNumberWithTwoDecimalPlaces, trigger: 'blur' }
}
export default {
  namespaced: true,
  state
}
