<template>
  <el-dialog
    :title="uploadData.title"
    :visible.sync="uploadData.isOpen"
    width="30%"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <el-upload
      class="avatar-uploader"
      :action="action"
      :show-file-list="false"
      :on-success="handleUploadSuccess"
      :before-upload="beforeUpload"
      drag
      :headers="headers"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    </el-upload>
  </el-dialog>
</template>

<script>
export default {
  props: {
    uploadData: {
      type: Object
    }
  },
  data () {
    return {
      headers: {
        Authorization: window.sessionStorage.getItem('token')
      },
      action: `${process.env.VUE_APP_API_URL}${this.uploadData.action}`
    }
  },
  methods: {
    /* 上传成功 */
    handleUploadSuccess (res, file) {
      this.$emit('success', res)
    },
    /* 上传控制 */
    beforeUpload (file) {
      const fileType = this.uploadData.type.includes(file.type)
      const fileSize = file.size / 1024 / 1024 < Number(this.uploadData.size)
      if (!fileType) {
        this.$message.error('文件格式错误！')
      }
      if (!fileSize) {
        this.$message.error(`文件大小不能超过${this.uploadData.size}MB!`)
      }
      return fileType && fileSize
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$confirm('确认关闭？')
        .then(() => {
          this.$emit('close')
        })
        .catch((_) => {})
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-dialog {
  .el-dialog__body {
    text-align: center;
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
}
</style>
