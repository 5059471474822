/* 智慧南职APP */
export const appManageRouter = [
  {
    path: '/appManage/version',
    name: 'version',
    component: () => import('@/views/pages/appManage/version.vue'),
    meta: {
      title: '版本列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/appManage/newsList',
    name: 'newsList',
    component: () => import('@/views/pages/appManage/newsList'),
    meta: {
      title: '新闻列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/appManage/publishNews',
    name: 'publishNews',
    component: () => import('@/views/pages/appManage/publishNews'),
    meta: {
      title: '发布新闻',
      role: ['超级管理员']
    }
  },
  {
    path: '/appManage/activity',
    name: 'activity',
    component: () => import('@/views/pages/appManage/activity/activity.vue'),
    meta: {
      title: '活动管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/appManage/activity/activityForm',
    name: 'activityForm',
    component: () => import('@/views/pages/appManage/activity/component/activityForm.vue'),
    meta: {
      title: '活动管理-表单',
      role: ['超级管理员']
    }
  },
  {
    path: '/appManage/noticeList',
    name: 'noticeList',
    component: () => import('@/views/pages/appManage/notice/noticeList.vue'),
    meta: {
      title: '消息通告',
      role: ['超级管理员']
    }
  },
  {
    path: '/appManage/popUpNotice',
    name: 'popupNotice',
    component: () => import('@/views/pages/appManage/pop-up-notice/popUpNotice'),
    meta: {
      title: '首页弹窗',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/appSign',
    name: 'appSign',
    component: () => import('@/views/pages/studentDepartment/app-sign/appSign'),
    meta: {
      title: 'app签到情况',
      role: ['超级管理员']
    }
  }
]
