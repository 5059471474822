import request from '@/service/request'

// 获取参数列表
export const getParamsList = (params) => {
  return request.post('/sys/params/page', params)
}

// 添加参数
export const addParams = (params) => {
  return request.post('/sys/params/save', params)
}

// 删除参数
export const deleteParams = (params) => {
  return request.post('/sys/params/del/' + params)
}

// 根据id获取参数信息
export const getParamsById = (params) => {
  return request.post('/sys/params/getById/' + params)
}

// 根据key查询系统参数
export const getSysParamsByKey = (paramKey) => {
  return request.post('/sys/params/getByKey/' + paramKey)
}

// 根据key查询系统字典
export const getSysDictionaryByKey = (paramKey) => {
  return request.post('/sys/params/getDictionaryByKey/' + paramKey)
}
