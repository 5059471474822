import request from '@/service/request'

// 登录
export const loginApi = (params) => {
  // return request.post('/auth/login', params)
  return request.post('/auth/manager/login', params)
}

// 登录 新版
// export const login = (params) => {
//   return request.post('/auth/manager/login', params)
// }

// 获取用户菜单
export const getUserMenu = (params) => {
  return request.post('/auth/listTree')
}

// 获取用户个人信息
export const getUserInfoApi = (params) => {
  return request.post('/auth/getCurrentUser')
}

// 获取用户个人信息(根据用户id)
export const getUserInfoBuuserIdApi = (id) => {
  return request.post(`/auth/getSysUserById/${id}`)
}
