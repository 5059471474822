import axios from 'axios'
import { Loading, Message } from 'element-ui'

let loading

function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '正在加载',
    background: 'rgba(0, 0, 0, 0)'
  })
}

function endLoading() {
  loading.close()
}

/* 请求导出excel */
export const exportExcel = (host, path, method, params, filename, fileType = '.xlsx') => {
  startLoading()
  return new Promise((resolve, reject) => {
    axios({
      url: `${host}${path}`,
      method: method,
      responseType: 'blob',
      data: params,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: window.sessionStorage.getItem('token')
      }
    }).then((res) => {
      // 假如返回的数据类型是json，说明是报错了，通过FileReader()把报错显示在弹窗里
      if (res.data.type === 'application/json') {
        const reader = new FileReader()
        let result
        reader.onload = () => {
          result = JSON.parse(reader.result)
          Message.error(result.msg ? '导出失败：' + result.msg : '导出失败')
          reject(new Error(result.msg))
        }
        reader.readAsText(res.data)
      } else {
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `${filename}${fileType}`
        document.body.appendChild(a)
        a.click()
        resolve(res)
      }
    }).catch(err => {
      reject(err)
    }).finally(() => {
      endLoading()
    })
  })
}

/* 请求导入excel */
export const importExcel = (host, path, method, params) => {
  startLoading()
  return new Promise((resolve, reject) => {
    axios({
      url: `${host}${path}`,
      method: method,
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: window.sessionStorage.getItem('token')
      }
    }).then((res) => {
      endLoading()
      resolve(res)
    }).catch(err => {
      endLoading()
      reject(err)
    })
  })
}

/* 请求导出docx */
export const exportWord = (host, path, method, params, filename) => {
  startLoading()
  return new Promise((resolve, reject) => {
    axios({
      url: `${host}${path}`,
      method: method,
      responseType: 'blob',
      data: params,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: window.sessionStorage.getItem('token')
      }
    }).then((res) => {
      endLoading()
      const blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel;charset=utf-8'
      })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `${filename}.docx`
      document.body.appendChild(a)
      a.click()
      resolve(res)
    }).catch(err => {
      endLoading()
      reject(err)
    })
  })
}
