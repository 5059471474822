/* 宿管中心 */
export const dormRouter = [
  {
    path: '/dorm/dormBuild',
    name: 'dormBuild',
    component: () => import('@/views/pages/dormManage/dorm-build/dormBuild.vue'),
    meta: {
      title: '宿舍楼设置',
      role: ['超级管理员']
    }
  },
  {
    path: '/dorm/dormRoomType',
    name: 'dormRoomType',
    component: () => import('@/views/pages/dormManage/dorm-room-type/dormRoomType.vue'),
    meta: {
      title: '宿舍类型设置',
      role: ['超级管理员']
    }
  },
  {
    path: '/dorm/addDorm',
    name: 'addDorm',
    component: () => import('@/views/pages/dormManage/dormRoom.vue'),
    meta: {
      title: '新增宿舍',
      role: ['超级管理员']
    }
  },
  {
    path: '/dorm/dormRoomBed',
    name: 'dormRoomBed',
    component: () => import('@/views/pages/dormManage/dorm-room-bed/dormRoomBed.vue'),
    meta: {
      title: '入住管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/dorm/dormCount',
    name: 'dormCount',
    component: () => import('@/views/pages/dormManage/dormCount.vue'),
    meta: {
      title: '入住统计花名册',
      role: ['超级管理员']
    }
  },
  {
    path: '/dorm/numberCount',
    name: 'numberCount',
    component: () => import('@/views/pages/dormManage/number-count/numberCount.vue'),
    meta: {
      title: '新生入住统计',
      role: ['超级管理员']
    }
  },
  {
    path: '/dorm/photoApprove',
    name: 'photoApprove',
    component: () => import('@/views/pages/dormManage/photo-approve/photoApprove.vue'),
    meta: {
      title: '门禁照片审核',
      role: ['超级管理员']
    }
  },
  {
    path: '/dorm/dormAccess',
    name: 'dormAccess',
    component: () => import('@/views/pages/dormManage/dorm-access/dormAccess.vue'),
    meta: {
      title: '门禁权限模板',
      role: ['超级管理员']
    }
  },
  {
    path: '/dorm/checkInCount',
    name: 'checkInCount',
    component: () => import('@/views/pages/dormManage/checkInCount.vue'),
    meta: {
      title: '入住统计',
      role: ['超级管理员']
    }
  },
  {
    path: '/dorm/setAALockCard',
    name: 'setAALockCard',
    component: () => import('@/views/pages/dormManage/aalock/set-aalock-card/setAALockCard.vue'),
    meta: {
      title: '设置门卡',
      role: ['超级管理员']
    }
  },
  {
    path: '/dorm/reissueRecord',
    name: 'reissueRecord',
    component: () => import('@/views/pages/dormManage/aalock/reissue-record/ReissueRecord.vue'),
    meta: {
      title: '补卡记录',
      role: ['超级管理员']
    }
  },
  {
    path: '/dorm/aaLockIssue',
    name: 'aaLockIssue',
    component: () => import('@/views/pages/dormManage/aalock/aalock-issue/aaLockIssue.vue'),
    meta: {
      title: '智能锁反馈',
      role: ['超级管理员']
    }
  },
  {
    path: '/dorm/aaLockCardRecord',
    name: 'aaLockCardRecord',
    component: () => import('@/views/pages/dormManage/aalock/aalock-card-record/aaLockCardRecord.vue'),
    meta: {
      title: '门卡设置记录',
      role: ['超级管理员']
    }
  }
]
