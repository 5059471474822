import request from '@/service/request'

// 获取学区列表
const getCampusList = (params) => {
  return request.post('/sch/campus/page', params)
}

// 添加学区
const addCampus = (params) => {
  return request.post('/sch/campus/save', params)
}

// 删除学区
const deleteCampus = (params) => {
  return request.post('/sch/campus/del/' + params)
}

// 根据id获取学区信息
const getCampusById = (params) => {
  return request.post('/sch/campus/getById/' + params)
}

// 查询校区
const getCampusListQuery = () => {
  return request.post('/sch/campus/list', {})
}

export {
  getCampusList,
  addCampus,
  deleteCampus,
  getCampusById,
  getCampusListQuery
}
