/* 通用方法函数 */
import store from '@/store/index'
import _ from 'lodash'
import { getSysDictionaryByKey } from '@/api/sysParams'

// 检查数组中是否有重复的数据
export const hasDuplicateKey = (array, key) => {
  const set = new Set()
  for (let i = 0; i < array.length; i++) {
    if (set.has(array[i][key])) {
      return true
    }
    set.add(array[i][key])
  }
  return false
}

/* 克隆赋值
*  originData  {Object} 数据来源
* newData {Object} 被赋值的对象，只给 newData 存在的字段赋值,默认为 formData
* */
export const copyData = (originData, newData) => {
  Object.keys(newData).forEach(key => {
    newData[key] = originData[key]
  })
}

/* 根据id筛选出data
*
*
* */
export const getLabelById = (id, arr, key) => {
  return arr.find(item => item[key] === id)
}

/* 判断是否带有按钮权限
*  key {string} {array} 权限字段
*/
export const hasPermission = (key) => {
  let _array = []
  if (typeof key === 'string') { // 只有一个时,为字段
    _array.push(key)
  } else {
    _array = key
  }
  // 获取数据交集
  const _result = _.intersection(store.state.login.userInfo.paraNameList, _array)
  // 数据有数据,代表用户拥有该按钮权限
  return !!_result.length
}

/* 根据paramValue 获取系统字典名
* name {string} paramValue
* arr {Array} 用于获取字典名的list
 */
export const getParamName = (value, arr) => {
  let _result = ''
  if (value || !isNaN(value)) {
    for (let i = 0; i < arr.length; i++) {
      const {
        paramValue,
        paramName
      } = arr[i]
      let _para = paramValue
      if (!isNaN(value)) { // 判断value是否数字,是数字就把paramValue也转为数字类型
        _para = Number(paramValue)
        value = Number(value)
      }
      if (_para === value) {
        _result = paramName
        break
      }
    }
  }
  return _result
}

// 根据系统键获取系统参数list
export const getParamListByKey = async (paramKey, parent, arr) => {
  try {
    const res = await getSysDictionaryByKey(paramKey)
    parent[arr] = res.data
  } catch (e) {
  }
}
