/* 宿管中心 */
export const systemRouter = [
  {
    path: '/sys/user',
    name: 'userList',
    component: () => import('@/views/pages/system/userList/userList.vue'),
    meta: {
      title: '后台用户',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/appService',
    name: 'appService',
    component: () => import('@/views/pages/appManage/app-service/appService.vue'),
    meta: {
      title: 'app服务配置',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/appUser',
    name: 'appUser',
    component: () => import('@/views/pages/system/appUser/index.vue'),
    meta: {
      title: 'APP用户',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/role',
    name: 'roleList',
    component: () => import('@/views/pages/system/roleList/role.vue'),
    meta: {
      title: '角色列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/menu',
    name: 'menuList',
    component: () => import('@/views/pages/system/menuList/menuList.vue'),
    meta: {
      title: '菜单列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/params',
    name: 'paramsList',
    component: () => import('@/views/pages/system/paramsList/params.vue'),
    meta: {
      title: '系统参数',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/logOffManage',
    name: 'logOffManage',
    component: () => import('@/views/pages/system/log-off-manage/logOffManage.vue'),
    meta: {
      title: '销户管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/playground',
    name: 'playground',
    component: () => import('@/views/pages/system/playground/playground.vue'),
    meta: {
      title: '广场'
    }
  }
]
