import request from '@/service/request'

// 获取学费列表
export const getPriceList = (params) => {
  return request.post('/sch/price/page', params)
}

// 添加学费
export const addPrice = (params) => {
  return request.post('/sch/price/save', params)
}

// 删除学费
export const deletePrice = (params) => {
  return request.post('/sch/price/del/' + params)
}

// 根据id获取学费信息
export const getPriceById = (params) => {
  return request.post('/sch/price/getById/' + params)
}

// 根据专业id获取学费信息
export const getPriceByMajorId = (params) => {
  return request.post('/sch/price/getByMajorId', params)
}

// 添加查询年度费用
/**
 * @param {Number} academicYear 学年
 * @param {String} campusId 校区id
 * @param {String} collegeId 院系id
 * @param {String} majorId 专业ID
 */
export const getPriceListQuery = (params) => {
  return request.post('/sch/price/list', params)
}
