import request from '@/service/request'
import store from '@/store/index'

// 判断是否宿管

/* 1.宿舍楼 */
// 获取宿舍楼列表
export const getDormBuildList = (params) => {
  return request.post('/dormitory/dormitoryInfo/page', params)
}
// 楼况设置 list
export const listDormBuild = (params) => {
  return request.post('/dormitory/dormitoryInfo/list', params)
}

// 添加宿舍楼
export const addDormBuild = (params) => {
  return request.post('/dormitory/dormitoryInfo/save', params)
}

// 删除宿舍楼
export const deleteDormBuild = (id) => {
  return request.post(`/dormitory/dormitoryInfo/del/${id}`)
}

/* 获取楼层树
* filterLockFlag =1 安装了电子锁的宿舍（南校区）
* */
export const getDormTree = (params) => {
  /*  宿管('dorm:supervisor')只能加载宿管树(roomTreeByUser)
  * */
  const isDormitoryManager = store.state.login.userInfo.paraNameList.includes('dorm:supervisor')
  const api = isDormitoryManager ? '/dormitory/dormitoryInfo/roomTreeByUser' : '/dormitory/dormitoryInfo/roomTree'
  return request.post(api, params)
}

/* 获取楼层树 根据宿管 token
* filterLockFlag =1 安装了电子锁的宿舍（南校区）
* */
export const getDormTreeByManagerApi = (params) => {
  return request.post('/dormitory/dormitoryInfo/roomTreeByUser', params)
}

/* 获取楼层树 获取已绑定学生的宿舍，用于 电费增送绑定学生模块
*
 */
export const treeDormTreeHasStuApi = (params) => {
  return request.post('/dormitory/dormitoryInfo/roomTreeForHasStu', params)
}

// 根据id获取宿舍楼信息
export const getDormBuildById = (params) => {
  return request.post('/dormitory/dormitoryInfo/getById/' + params)
}

// 条件查询楼栋信息
export const getDormBuildQuery = (params) => {
  return request.post('/dormitory/dormitoryInfo/list', params)
}

// 条件查询楼栋信息
export const listDormBuildByStuId = (params) => {
  return request.post('/dormitory/dormitoryInfo/listByStuId/' + params.userId, params)
}

/* 2.添加宿舍房间 */
// 获取宿舍房间列表
export const getDormRoomList = (params) => {
  return request.post('/dormitory/dormitoryRoom/page', params)
}

// 添加宿舍房间
export const addDormRoom = (params) => {
  return request.post('/dormitory/dormitoryRoom/save', params)
}

/* 设置宿舍门锁码
*  @params {string} id
* @params {string} lockFeatureCode 门锁码
* */
export const saveLockFeatureCodeApi = (params) => {
  return request.post('dormitory/dormitoryRoom/saveLockFeatureCode', params)
}

// 删除宿舍房间
export const deleteDormRoom = (params) => {
  return request.post('/dormitory/dormitoryRoom/del/' + params)
}

// 根据id获取宿舍房间信息
export const getDormRoomById = (params) => {
  return request.post('/dormitory/dormitoryRoom/getById/' + params)
}

// 条件查询宿舍
// 条件查询房间类型
export const getDormRoomQuery = (params) => {
  return request.post('/dormitory/dormitoryRoom/list', params)
}
export const listDormRoomByDormitoryAndStuId = (params) => {
  return request.post('/dormitory/dormitoryRoom/getByDormitoryAndStuId', params)
}
export const deleteRoomBatch = (params) => {
  return request.post('/dormitory/dormitoryRoom/deleteRoomBatch', params)
}

/* 3.宿舍房间类型设置 */
// 获取宿舍房间类型列表
export const getDormRoomTypeList = (params) => {
  return request.post('/dormitory/dormitoryRoomType/page', params)
}

// 添加宿舍房间类型
export const addDormRoomType = (params) => {
  return request.post('/dormitory/dormitoryRoomType/save', params)
}

// 删除宿舍房间类型
export const deleteDormRoomType = (params) => {
  return request.post('/dormitory/dormitoryRoomType/del/' + params)
}

// 根据id获取宿舍房间类型信息
export const getDormRoomTypeById = (params) => {
  return request.post('/dormitory/dormitoryRoomType/getById/' + params)
}

// 条件查询房间类型
export const getDormRoomTypeListQuery = (params) => {
  return request.post('/dormitory/dormitoryRoomType/list', params)
}

/* 4.宿舍床位信息 */
// 获取床位列表
export const getDormRoomBedList = (params) => {
  return request.post('/dormitory/dormitoryBed/page', params)
}

// 添加床位
export const addDormRoomBed = (params) => {
  return request.post('/dormitory/dormitoryBed/save', params)
}

// 添加一个床位
export const saveABed = (params) => {
  return request.post('/dormitory/dormitoryBed/saveABed', params)
}

// 删除床位
export const deleteDormRoomBed = (params) => {
  return request.post('/dormitory/dormitoryBed/del/' + params)
}

// 根据id获取床位信息
export const getDormRoomBedById = (params) => {
  return request.post('/dormitory/dormitoryBed/getById/' + params)
}

// 条件查询床位
export const getDormRoomBedListQuery = (params) => {
  return request.post('/dormitory/dormitoryBed/list', params)
}

// 5.宿舍报修
// 获取宿舍报修列表
export const getDormRepairList = (params) => {
  return request.post('/dormitory/dormitoryRepair/page', params)
}

// 添加宿舍报修
export const saveRepairApi = (params) => {
  return request.post('/dormitory/dormitoryRepair/save', params)
}

/* 宿舍报修
*  status {number} 维修状态：-1：已撤销，0：未处理，1：处理中，2：已处理
*/
export const changeDormRepairStatusApi = (params) => {
  return request.post('/dormitory/dormitoryRepair/saveStatus', params)
}

// 根据id获取宿舍报修
export const getDormRepairById = (id) => {
  return request.post(`/dormitory/dormitoryRepair/getById/${id}`)
}

// 根据id删除宿舍报修
export const deleteDormRepair = (params) => {
  return request.post('/dormitory/dormitoryRepair/del/' + params)
}

// 获取入住花名册
export const getDormRoomBedInfo = (params) => {
  return request.post('/dormitory/dormitoryRoom/getDormitoryMsg', params)
}

// 获取入住状态
export const getDormRoomStatus = (params) => {
  return request.post('/dormitory/dormitoryRoom/getMajorAndDormitoryStatus', params)
}
