/* 合同审核*/
export const contractRouter = [
    {
      path: '/contract/contract',
      name: 'Tree',
      component: () => import('@/views/pages/contract/contract.vue'),
      meta: {
        title: '合同审核',
        role: ['超级管理员']
      }
  },
  {
    path: '/contract/transact',
    name: 'Tree',
    component: () => import('@/views/pages/contract/transact.vue'),
    meta: {
      title: '经办合同',
      role: ['超级管理员']
    }
  },

  ]
  