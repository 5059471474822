import store from '@/store/index'

function role (el, binding) {
  const { value } = binding
  const roles = store.getters['login/getRoles']

  if (value && value instanceof Array) {
    if (value.length > 0) {
      const permissionRoles = value

      const hasPermission = roles.some(role => {
        return permissionRoles.includes(role)
      })

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  } else {
    throw new Error('need roles! Like v-permission="[\'admin\',\'editor\']"')
  }
}

export default {
  inserted (el, binding) {
    role(el, binding)
  },
  update (el, binding) {
    role(el, binding)
  }
}
