<template>
  <el-scrollbar>
    <div class="page-container">
      <div class="logo-box">
        <!-- <img src="../../../../assets/image/logo.png" class="logo" alt="" /> -->
        <span v-if="showTitle" class="name">广东南方职业学院管理系统</span>
      </div>
      <el-menu
        :collapse="isOpen"
        background-color="#032449"
        text-color="#ccc"
        active-text-color="#1890ff"
        router
        mode="vertical"
        :collapse-transition="false"
        :unique-opened="true"
        :default-active="defaultActive"
      >
        <el-menu-item @click="addDefualtRoute" index="/home">
          <i class="el-icon-s-home"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <template v-for="item in menuList">
          <template v-if="item.children">
            <el-submenu :key="`${item.id}`" :index="item.path||item.id">
              <template #title>
                <i :class="item.icon" class="subitem-left-icon"></i>
                <span class="menu-title">{{ item.title }}</span>
              </template>
              <template v-for="subItem in item.children">
                <ElSubmenu v-if="subItem.children" :key="`${subItem.id}`"
                           :index="subItem.id">
                  <template #title>
                    <i class="menu-icon" :class="subItem.icon"></i>
                    <span class="menu-title">{{ subItem.title }}</span>
                  </template>
                  <ElMenuItem
                    v-for="thirdItem in subItem.children"
                    :key="`${thirdItem.id}`"
                    :index="`${item.path}${thirdItem.path}`"
                    @click="handleAddTabRoute(item,thirdItem)"
                  >
                    <template #title>
                      <i class="menu-icon" :class="thirdItem.icon"></i>
                      <span class="menu-title">{{ thirdItem.title }}</span>
                    </template>
                  </ElMenuItem>
                </ElSubmenu>
                <ElMenuItem v-else :key="`${subItem.id}`" :index="`${item.path}${subItem.path}`"
                            @click="handleAddTabRoute(item, subItem)">
                  <template #title>
                    <i class="menu-icon" :class="subItem.icon"></i>
                    <span class="menu-title">{{ subItem.title }}</span>
                  </template>
                </ElMenuItem>
              </template>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item :key="`${item.id}`" :index="item.path||item.id">
              <template #title>
                <i class="menu-icon" :class="item.icon"></i>
                <span class="menu-title">{{ item.title }}</span>
              </template>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </div>
  </el-scrollbar>
</template>

<script>
import { getUserMenu } from '@/api/login'
import { mapState, mapMutations } from 'vuex'

export default {
  data () {
    return {
      menuList: [],
      showTitle: true
    }
  },
  computed: {
    ...mapState('header', {
      isOpen: (state) => state.isOpen
    }),
    ...mapState('activeRouter', {
      activeRouteInfo: state => state.activeRouteInfo
    }),
    defaultActive () {
      const routeInfo = JSON.parse(window.sessionStorage.getItem('activeRouteInfo'))
      const d3 = this.activeRouteInfo.path
      if (this.activeRouteInfo.default) {
        return d3
      } else if (this.activeRouteInfo) {
        const d1 = `${this.activeRouteInfo.parentPath}${this.activeRouteInfo.path}`
        return d1
      } else if (routeInfo) {
        const d2 = `${routeInfo.parentPath}${routeInfo.path}`
        return d2
      } else {
        return d3
      }
    }
  },
  watch: {
    isOpen (data) {
      if (!data) {
        setTimeout(() => {
          this.showTitle = true
        }, 200)
      } else {
        this.showTitle = false
      }
    }
  },
  created () {
    getUserMenu().then((res) => {
      if (res.code === 200) {
        this.menuList = res.data
      }
    })
  },
  methods: {
    ...mapMutations('activeRouter', ['addTabRoute', 'setDefaultRoute']),
    // 添加tab路由
    handleAddTabRoute (parentRouteInfo, secondRouteInfo) {
      this.addTabRoute({
        parent: parentRouteInfo,
        children: secondRouteInfo
      })
    },
    // 设置默认激活路由
    addDefualtRoute () {
      this.setDefaultRoute()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100vh;

  .logo-box {
    color: $color-info-light;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    cursor: pointer;

    .name {
      font-size: 14px;
      font-weight: bold;
      padding-left: 10px;
    }

    .logo {
      width: 25px;
      height: 25px;
      vertical-align: middle;
    }
  }

  .el-menu {
    .el-menu-item-group {
      ::v-deep .el-menu-item-group__title {
        padding: 0;
      }
    }

    .subitem-left-icon {
      margin-right: 5px;
      width: 24px;
      font-size: 18px;
      text-align: center;
      vertical-align: middle;
    }
  }
}
</style>
