/**
 * dialog mixins
 */
import { isEqual, throttle } from 'lodash'
import { validateIdNumber, validateNumber } from '@/utils/form-validate'

export default {
  props: {
    visible: { // 显示弹窗
      type: Boolean,
      default: false
    },
    closeOnClickModal: Boolean // 点击空白处关闭
  },
  data() {
    return {
      id: '',
      title: '',
      preTitle: '',
      show: false,
      initData: {}, // 原始formData
      updateFlag: true, // false-formData未修改, true-formData已被修改
      loadData: {
        loading: false
      },
      rules: {
        remarks: {
          message: '不能超过200个字',
          trigger: 'blur',
          max: 200
        },
        stuName: {
          message: '不能超过50个字',
          trigger: 'blur',
          max: 50
        },
        examineNo: [
          this.$store.state.commonValidate.limit30WordsObj
        ],
        idNumber: [
          {
            required: true,
            message: '请填写身份证号',
            trigger: 'blur'
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              // idCardType 证件类型， 身份证or 港澳身份证
              if (Object.keys(this.formData).includes('idCardType')) {
                if (this.formData.idCardType) {
                  validateIdNumber(rule, value, callback)
                } else {
                  callback()
                }
              } else {
                validateIdNumber(rule, value, callback)
              }
            },
            trigger: 'blur'
          }
        ],
        sort: {
          validator: validateNumber,
          trigger: 'blur'
        }
      }
    }
  },
  methods: {
    // 保存最开始的formData数据,用于比较是否修改表单
    setInitData() {
      this.initData = this.cloneDeep(this.formData)
    },
    // 比较数据是否有修改
    compareData() {
      this.updateFlag = false
      Object.keys(this.formData).forEach(key => {
        const result = isEqual(this.formData[key], this.initData[key])
        if (!result) {
          // formData 的字段值存在且与initData的不相等时,才判断为 true 已修改
          if (this.formData[key] || Number(this.formData[key]) === 0) {
            // 已修改
            this.updateFlag = true
          }
        }
      })
    },
    closed() {
      // 关闭 + 请求父级方法
      this.$emit('on-close')
      this.show = false
    },
    close() {
      // 单纯关闭
      this.show = false
    },
    closeForm() {
      if (this.$refs.form) {
        if (this.$refs.form.$refs.form) {
          this.$refs.form.$refs.form.resetFields()
        } else {
          this.$refs.form.resetFields()
        }
      }
      this.$emit('on-close')
      this.close()
    },
    // 取消修改,关闭弹窗,需要先判断是否修改数据
    cancel() {
      this.compareData()
      if (this.updateFlag) {
        this.$confirm('是否放弃保存已被修改的数据?', '取消保存').then(() => {
          this.close()
        })
      } else {
        this.close()
      }
    },
    // 清空数组
    clearList(arr) {
      while (arr.length) arr.pop()
    },
    // 根据id加载formData,id可以为string,也可以为object
    async getDataById(id, apiMethod) {
      this.loadData.loading = true
      try {
        const res = await apiMethod(id)
        Object.keys(this.formData).forEach((key) => {
          this.formData[key] = res.data[key]
        })
        this.setInitData()
      } catch (e) {
        if (!e) {
          this.$message.error('数据获取失败，请重试！')
        }
        this.close()
      } finally {
        this.loadData.loading = false
      }
    },
    save: throttle(function(name, apiMethod, successMsg = '') {
      if (this.loadData.loading) {
        this.$message.error('请勿重复提交!')
        return
      }
      // 判断表单是否有更新
      this.compareData()
      if (!this.updateFlag) {
        this.$message.error('表单未被修改!')
        return
      }
      this.$refs.form.validate((valid) => {

        if (!valid) {
          this.$message('未通过校验,可能是漏填必填项!')
          return false
        }
        const _editType = this.preTitle ? this.preTitle : (this.id ? '编辑' : '新增')
        this.$confirm(`此操作将${_editType}${name}, 是否继续?`, '提示', {
          dangerouslyUseHTMLString: true
        }).then(async () => {
          this.loadData.loading = true
          try {
            /* 保存参数过滤规则
            *  1.为null 的不提交
            *  2.空数组的不提交
            *  3.空字符串的不提交（原本有值改空字符串的提交）
            *  4.数字0提交
            * */
            const _formData = {}
            Object.keys(this.formData).filter(key => (!Array.isArray(this.formData[key]) && !!this.formData[key]) || !!this.formData[key]?.length || this.formData[key] === 0 || this.formData[key] !== this.initData[key]).forEach(k => {
              _formData[k] = this.formData[k]
            })
            await apiMethod(_formData)
            const _successMsg = successMsg || `${_editType}${name}成功`
            this.$message.success(_successMsg)
            this.closed()
          } catch (e) {
          } finally {
            this.loadData.loading = false
          }
        }).catch()
      })
    }, 1000)
  },
  watch: {
    visible: {
      immediate: true,
      handler(newVal) {
        this.show = newVal
      }
    },
    show(newVal) {
      if (newVal !== this.visible) {
        this.$emit('update:visible', newVal)
      }
      if (!newVal) {
        this.$emit('close')
      }
    }
  },
  beforeDestroy() {
    if (this.show) {
      this.close()
    }
  }
}
