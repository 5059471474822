/* 人事处 */
export const hrDepartmentRouter = [
  {
    path: '/hr/hrManage',
    name: 'hrManage',
    component: () => import('@/views/pages/system/userList/userList.vue'),
    meta: {
      title: '人事管理',
      role: ['超级管理员']
    }
  }
]
