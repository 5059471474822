<template>
  <div class="table-view">
    <header class="table-view__header">
      <slot name="header"></slot>
      <div class="font-right table-view__header-right" v-if="headerRight">
        <slot name="header-right"></slot>
      </div>
    </header>
    <div class="table-view__body">
      <slot />
    </div>
    <div class="pagination-body table-view__footer" v-if="showPage">
      <el-pagination
        :background="background"
        :current-page="queryInfo.pageNum"
        :page-size="queryInfo.pageSize"
        :page-sizes="pageSizes"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import { throttle } from 'lodash'

export default {
  name: 'tableView',
  props: {
    headerRight: Boolean, // 搜索条右边
    background: Boolean, // 背景
    total: {
      type: Number,
      default: 0
    },
    queryInfo: { // 搜索参数
      pageNum: {
        type: Number,
        default: 1
      },
      pageSize: {
        type: Number,
        default: 10
      }
    },
    showPage: { // 是否显示页码
      type: Boolean,
      default: true
    },
    pageSizes: {
      default: () => ([10, 15, 20, 30, 40, 50, 100]),
      type: Array
    }
  },
  data () {
    return {}
  },
  methods: {
    handleSizeChange: throttle(function (size) {
      this.queryInfo.pageSize = size
      this.$emit('reload-table')
    }, 500, {}),
    handleCurrentChange: throttle(function (num) {
      this.queryInfo.pageNum = num
      this.$emit('reload-table')
    }, 500, {})
  }
}
</script>

<style lang="scss">
.table-view {
  box-sizing: border-box;
  padding-top: var(--space-primary);
  background: #fff;

  .table-view__header {
    padding: 0 var(--space-primary);

    .view-search-form {
      .el-form-item {
        margin-bottom: 10px;
      }
    }

    .header-button {
      margin-right: 8px;
      margin-bottom: var(--space-primary);
    }
  }

  .table-view__header-right {
    margin-bottom: var(--space-primary);

    .el-button {
      margin-left: var(--space-primary);
    }
  }

  .table-view__body {
    margin: 0 10px;
  }

  .table-view__footer {
    .el-pagination {
      padding: 15px;
      text-align: center;
    }

    .is-background {
      background-color: #fff;
    }
  }
}
</style>
