import store from '@/store/index'

const systemParam = ['grade', 'stuStatus', 'teacherType', 'majorType', 'lengthOfSchoolingType']

// 系统参数与字典
const systemParamRequest = () => {
  systemParam.forEach(item => {
    store.dispatch('systemParam/getSystemDictionary', item).then()
  })
}

export {
  systemParamRequest
}
