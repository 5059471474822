/* 讲座管理 */
export const principalOfficeRouter = [
  {
    path: '/principalOffice/principalMailBox',
    name: 'feedback',
    component: () => import('@/views/pages/principal-office/principal-mail-box/principalMailBox.vue'),
    meta: {
      title: '校长信箱',
      role: ['超级管理员']
    }
  },
  {
    path: '/principalOffice/lecturePublish',
    name: 'lecturePublish',
    component: () => import('@/views/pages/principal-office/lecturePublish.vue'),
    meta: {
      title: '讲座发布',
      role: ['超级管理员']
    }
  },
  {
    path: '/principalOffice/lecturePublishEdit',
    name: 'lecturePublishEdit',
    component: () => import('@/views/pages/principal-office/lecturePublishEdit.vue'),
    meta: {
      title: '讲座发布编辑',
      role: ['超级管理员']
    }
  }
]
