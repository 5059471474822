<template>
  <div class="header-container">
    <div class="left-box">
      <div class="icon-top">
        <i class="el-icon-s-fold" @click="toggleCollapse"></i>
      </div>
      <el-breadcrumb replace separator="/">
        <el-breadcrumb-item
          @click.native="removeRouteHandle"
          :to="{ path: '/home' }"
        >首页
        </el-breadcrumb-item
        >
        <span v-if="!activeRouteInfo.default">
          <el-breadcrumb-item
          >{{ activeRouteInfo.parentName }}</el-breadcrumb-item
          >
        <el-breadcrumb-item
        >{{ activeRouteInfo.title }}</el-breadcrumb-item
        >
        </span>
      </el-breadcrumb>
    </div>
    <div class="right-box">
      <i class="el-icon-rank" id="fullScreen" title="全屏" @click="fullScreen"></i>
<!--      <span class="username">{{ userInfo.roleNameList[ userInfo.roleNameList.length-1] }}</span>-->
      <span class="username">{{ userInfo.username }}</span>
      <span class="username">{{ userInfo.realName }}</span>
      <el-dropdown @command="logoutHandle">
        <el-avatar shape="square" src='https://picsum.photos/100/100'/>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="password">修改密码</el-dropdown-item>
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <modPassword
      ref="dialogRef"
      :visible.sync="visibleDialog"
      v-if="visibleDialog"
      @confirm="modPasswordHandle" />
  </div>
</template>
<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import modPassword from '@/components/modPassword/index.vue'
import { modUserPassword } from '@/api/system/menu-api'

export default {
  components: {
    modPassword
  },
  data () {
    return {
      visibleDialog: false
    }
  },
  computed: {
    comptAvatar () {
      const _result = this.$store.state.login.userInfo.realImg ? `${this.$fileUrl}${this.$store.state.login.userInfo.realImg}` : 'https://picsum.photos/100/100'
      return _result
    },
    ...mapState('header', {
      isOpen: (state) => state.isOpen
    }),
    ...mapState('activeRouter', {
      activeRouteInfo: state => state.activeRouteInfo
    }),
    ...mapState('login', {
      userInfo: (state) => state.userInfo
    })
  },
  methods: {
    /* 展开与隐藏菜单 */
    ...mapMutations('header', ['setOpen']),
    ...mapMutations('activeRouter', ['setActiveRouter']),
    ...mapActions('login', ['logout']),
    toggleCollapse () {
      this.setOpen(!this.isOpen)
    },
    // 修改密码
    modPasswordHandle (data) {
      modUserPassword(data).then(res => {
        this.$confirm('密码修改成功!请重新登录').then().finally(() => {
          this.logout()
          this.$router.push('/login')
        })
      })
    },
    /* 退出登录 */
    logoutHandle (command) {
      if (command === 'logout') {
        this.logout()
        this.$router.push('/login')
      }
      if (command === 'password') {
        this.visibleDialog = true
      }
    },
    /* 清空当前路由信息 */
    removeRouteHandle () {
      this.setActiveRouter([])
    },
    /* 浏览器全屏 */
    fullScreen () {
      const element = document.documentElement
      if (this.fullscreen) {
        this.$message.success('退出全屏')
        this.fullscreenTitle = '进入全屏'
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        this.$message.success('进入全屏')
        this.fullscreenTitle = '退出全屏'
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      this.fullscreen = !this.fullscreen
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  background-color: $color-white;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;

  .left-box {
    display: flex;
    flex: 1;

    .icon-top {
      padding-left: 20px;
      display: flex;
      align-items: center;

      i {
        cursor: pointer;
        font-size: 24px;

        &:hover {
          color: #409eff;
        }
      }
    }

    .el-breadcrumb {
      height: 56px;
      line-height: 56px;
      padding: 0 20px;
    }
  }

  .right-box {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .el-icon-rank {
      font-size: 26px;
      padding: 0 20px;
      transform: rotate(45deg);
      cursor: pointer;

      &:hover {
        color: #409eff;
      }
    }

    .username {
      padding-right: 20px;
    }

    .el-avatar {
      cursor: pointer;
    }
  }
}
</style>
