import request from '@/service/request'

// 获取部门列表
const getDepartmentList = (params) => {
  return request.post('/sch/dept/page', params)
}

// 添加部门
const addDepartment = (params) => {
  return request.post('/sch/dept/save', params)
}

// 删除部门
const deleteDepartment = (params) => {
  return request.post('/sch/dept/del/' + params)
}

// 根据id获取部门信息
const getDepartmentById = (params) => {
  return request.post('/sch/dept/getById/' + params)
}

// 添加查询部门
/**
 * @param {String} campusId 校区id
 */
const getDepartmentListQuery = (params) => {
  return request.post('/sch/dept/list', params)
}

export {
  getDepartmentList,
  addDepartment,
  deleteDepartment,
  getDepartmentById,
  getDepartmentListQuery
}
