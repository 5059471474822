<template>
  <div class="cancel-popover mr-1">
    <el-popover
      ref="cancelPopRef"
      v-model="visible"
      placement="left"
      title="取消修改"
      width="220"
      trigger="click"
    >
      <p style="line-height:2">是否放弃保存已被修改的数据?</p>
      <div style="text-align: right">
        <el-button size="small" @click="cancelClose">取消</el-button>
        <el-button type="danger" plain size="small" @click="$parent.$parent.close()">确认取消</el-button>
      </div>
    </el-popover>
    <el-button :disabled="disabled" v-popover:popover @click="cancel">取消</el-button>
  </div>
</template>

<script>
export default {
  name: 'cancelPopover',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    updateFlag: Boolean
  },
  data () {
    return {
      visible: false
    }
  },
  mounted () {
  },
  methods: {
    cancel (e) {
      this.$emit('update:disabled', true)
      this.$parent.$parent.compareData()
      this.$nextTick(() => {
        if (this.updateFlag) {
          if (this.$refs?.cancelPopRef) {
            this.visible = false
            this.$refs.cancelPopRef.doDestroy(true)
          }
          this.$nextTick(() => {
            this.$refs.cancelPopRef.referenceElm = e.target
            this.visible = true
          })
        } else {
          this.$emit('update:disabled', false)
          this.$parent.$parent.close()
        }
      })
    },
    cancelClose () {
      this.visible = false
      this.$emit('update:disabled', false)
    }
  }
}
</script>

<style lang="scss">
.cancel-popover {
  display: inline-block;
}
</style>
