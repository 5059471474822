<template>
  <el-container class='home-container'>
    <el-aside :width="isOpen ? '64px' : '200px'">
      <menus></menus>
    </el-aside>
    <!--  el-main 设置高度，用于隐藏.right-layout__main 的滚动条  -->
    <el-main style='height: 100vh;overflow:hidden;'>
      <right-main></right-main>
    </el-main>
  </el-container>
</template>

<script>
import menus from './layout/left/index.vue'
import rightMain from './layout/right/index.vue'
import { mapState } from 'vuex'

export default {
  components: {
    menus,
    rightMain
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('header', {
      isOpen: (state) => state.isOpen
    })
  }
}
</script>

<style lang='scss' scoped>
.home-container {

  .el-aside {
    background-color: $color-aside-bg;
    transition: 0.3s ease-out;
    overflow-y: hidden;
    overflow-x: hidden;

    ::v-deep .el-menu {
      border: none;
    }
  }

  .el-main {
    background-color: $page-background-color;
    padding: 0;
  }
}
</style>
