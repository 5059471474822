import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router/index'

/* 请求配置 */
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8'
  }
})
// ip: '192.168.10.5'
// ip: '192.168.10.170',
// ip: '192.168.10.227',
// ip: '192.168.10.170'

/* 请求前拦截 */
service.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
}, error => {
  Message.error({ message: '请求超时!' })
  return Promise.reject(error)
})

/* 响应拦截 */
service.interceptors.response.use(response => {
  const res = response.data
  if (res.code === 401) {
    Message.error(res.msg || { message: 'token验证失败' })
    window.sessionStorage.clear()
    router.push('/login')
    return Promise.reject(new Error('Error'))
  }
  if (res.code === 403) {
    Message.error(res.msg || { message: '暂无权限' })
    return Promise.reject(new Error('Error'))
  }
  if (!res.success) {
    Message.error(res.msg || { message: '系统错误' })
    return Promise.reject(new Error('Error'))
  } else {
    return res
  }
}, error => {
  return Promise.reject(error)
})

export default service
