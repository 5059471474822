/* 基础信息  2024/4/16 此模块从基础信息并入到系统设置内 */
export const baseInfoRouter = [

  {
    path: '/sys/college',
    name: 'college',
    component: () => import('@/views/pages/baseInfo/college/college.vue'),
    meta: {
      title: '院系管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/major',
    name: 'major',
    component: () => import('@/views/pages/baseInfo/major/index.vue'),
    meta: {
      title: '专业管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/classes',
    name: 'classes',
    component: () => import('@/views/pages/baseInfo/classes/classes.vue'),
    meta: {
      title: '班级管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/price',
    name: 'price',
    component: () => import('@/views/pages/baseInfo/studyPrice/price.vue'),
    meta: {
      title: '学费管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/grade',
    name: 'grade',
    component: () => import('@/views/pages/baseInfo/grade/grade.vue'),
    meta: {
      title: '年级管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/personnel/teacher',
    name: 'teacher',
    component: () => import('@/views/pages/teacherManage/index.vue'),
    meta: {
      title: '教职工管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/department',
    name: 'department',
    component: () => import('@/views/pages/baseInfo/department/department.vue'),
    meta: {
      title: '部门管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/sys/campus',
    name: 'campus',
    component: () => import('@/views/pages/baseInfo/campus/campus.vue'),
    meta: {
      title: '校区管理',
      role: ['超级管理员']
    }
  }
]
